import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, IconButton, Paper, Stack, Typography, useTheme } from '@mui/material';
import kurentoUtils from 'kurento-utils';
import { IParticipant, useStores } from 'src/models';
import ParticipantsDetail from 'src/screens/conference/participants/ParticipantsDetail';
import useResponsive from 'src/hooks/useResponsive';
import { toJS } from 'mobx';
import { getIconByAuthCd } from 'src/utils/common';
import Iconify from 'src/components/iconify/Iconify';
import { ReactComponent as IcoLockOpen } from 'src/assets/icons/ico-lockOpen.svg';
import { ReactComponent as IcoLockClose } from 'src/assets/icons/ico-lockClose.svg';
import adapter from 'webrtc-adapter';
/**
 * ## Participant 설명
 *
 */

type Props = {
  participant: IParticipant;
  sendMessage: (message: any) => void;
  onConnectionstatechange: (
    peerConnection: any,
    refresh?: VoidFunction,
    participantCUID?: string,
    userName?: string,
  ) => void;
  setPeer?: (cuid: string, uid: string, peer: any, stream: any) => void;
  videostate?: any;
  ToggleMic?: any;
  ToggleVideo?: any;
  Mestream?: any;
  myStream?: any;
  videoWidth?: any;
  videoHeight?: any;
  videoView?: any;
  screenMode?: any;
  videoStream?: any;
  detaildata?: any;
  setNoCam?: any;
  onButtonFlag?: any;
  setOpenScreen?: any;
  browserName?: any;
  returnAlertFlag?: (flag: boolean, message: any) => void;
  layout?: (layout: string) => void;
  type?: string;
  pinMode?: boolean;
  videoFirstCUid?: string;
  mobile?: boolean;
  facingMode?: 'user' | 'environment';
  isUnderPerformance?: boolean;
};
export const Participant = observer(
  ({
    participant,
    sendMessage,
    onConnectionstatechange,
    setPeer,
    videostate,
    ToggleMic,
    ToggleVideo,
    Mestream,
    myStream,
    videoWidth,
    videoHeight,
    videoView,
    screenMode,
    videoStream,
    detaildata,
    setNoCam,
    onButtonFlag,
    setOpenScreen,
    browserName,
    returnAlertFlag,
    layout,
    type,
    pinMode,
    videoFirstCUid,
    mobile,
    facingMode,
    isUnderPerformance,
  }: Props) => {
    let peer: any;
    // console.log('* Log.', 'peer', participant.userName, participant.cuid);
    const theme = useTheme();
    const [speaker, setSpeaker] = useState(0);
    const rootStore = useStores();
    const { pushAlarmStore } = rootStore;
    const isDownMd = useResponsive('down', 'md');
    const videoRef = useRef<any>(); // createRef<HTMLVideoElement>();
    const AudioCtx = window.AudioContext; // || window.webkitAudioContext;
    const myUID = sessionStorage.getItem('myUID');
    const myCUID = sessionStorage.getItem('myCUID');
    const speakerRef = useRef(false);
    let constraints = {
      audio: {
        // echoCancellation: true,
        // noiseSuppression: true,
        sampleRate: pushAlarmStore.getSampleRateData() ? pushAlarmStore.getSampleRateData() : 48000,
        echoCancellation: { exact: true },
        autoGainControl: { exact: false },
        noiseSuppression: { exact: false },
        highpassFilter: { exact: false },
      },
      video: {
        width: isUnderPerformance ? 360 : 1280,
        framerate: 30,
      },
    };
    // console.log('🚀🚀🚀 ~ constraints:', constraints.video);

    const refresh = (_participantCUID?: string) => {
      if (_participantCUID) {
        if (_participantCUID !== myCUID) {
          // console.log('재수신 : ', participant.userName, _participantCUID)
          onRecive();
          return;
        }
      }

      if (myStream) {
        myStream.getTracks().forEach((track: any) => {
          track.stop();
        });
      }

      if (videoView !== 'audio') {
        const video = document.getElementById('video-' + participant.uid) as HTMLVideoElement;
        if (video) {
          video.srcObject = null;
        }
      } else if (videoView === 'audio') {
        const video = document.getElementById('video-' + participant.uid) as HTMLAudioElement;
        if (video) {
          video.srcObject = null;
        }
      }

      const msg = {
        type: 'kurento',
        id: 'broadcastRestarted',
        uid: myUID,
      };
      ToggleMic(false);
      ToggleVideo(false);
      sendMessage(msg);
      let cnts: any;
      let audioOption: any = false;
      let videoOption: any = false;
      if (participant.stream?.activities.length) {
        for (let i = 0; i < participant.stream?.activities.length; i++) {
          if (participant.stream?.activities[i] === 'AUDIO') {
            audioOption = {
              sampleRate: pushAlarmStore.getSampleRateData()
                ? pushAlarmStore.getSampleRateData()
                : 48000,
              // echoCancellation: true,
              echoCancellation: { exact: true },
              autoGainControl: true,
              noiseSuppression: false,
              deviceId: videostate.audioInput,
            };
            ToggleMic(true);
          }
          if (participant.stream?.activities[i] === 'VIDEO') {
            if (videostate.videoSize === '720p') {
              videoOption =
                mobile && myCUID === participant?.cuid
                  ? {
                      deviceId: videostate.videoInput,
                      width: { ideal: '1280' },
                      height: { ideal: '720' },
                      facingMode: { exact: facingMode },
                    }
                  : {
                      deviceId: videostate.videoInput,
                      width: { ideal: '1280' },
                      height: { ideal: '720' },
                    };
            } else {
              videoOption = videoOption =
                mobile && myCUID === participant?.cuid
                  ? {
                      deviceId: videostate.videoInput,
                      width: { ideal: '640' },
                      height: { ideal: '360' },
                      facingMode: { exact: facingMode },
                    }
                  : {
                      deviceId: videostate.videoInput,
                      width: { ideal: '640' },
                      height: { ideal: '360' },
                    };
            }
            ToggleVideo(true);
          }
        }
        cnts = {
          audio: audioOption,
          video: videoOption,
        };
        navigator.mediaDevices
          // .getUserMedia(cnts)
          .getUserMedia(cnts)
          .then((stream) => {
            let videostate: any = {};
            videostate.localStream = stream;
            let _stream = stream;
            const data: any = {};
            if (stream.getAudioTracks().length !== 0) {
              data.aCtx = new AudioCtx({
                sampleRate: pushAlarmStore.getSampleRateData()
                  ? pushAlarmStore.getSampleRateData()
                  : 48000,
              });
              data.gainNode = data.aCtx.createGain();
              data.gainNode.gain.value = participant.micLevel;
              data.analyser = data.aCtx.createAnalyser();
              data.aSrc = data.aCtx.createMediaStreamSource(stream);
              data.aSrc.connect(data.gainNode);
              data.gainNode.connect(data.analyser);
              data.aDest = data.aCtx.createMediaStreamDestination();
              data.analyser.connect(data.aDest);
              _stream = data.aDest.stream;
              // console.log('data', data);
              stream.getTracks().forEach(function (track) {
                _stream.addTrack(track);
              });
            }
            videostate.data = data;
            videostate.stream = _stream;
            videoStream(videostate);
            Mestream(videostate.stream);
            onSend(videostate.stream);
          })
          .catch((err) => {
            sendMessage({
              id: 'devicesAltered',
              uid: participant.uid,
              audio: false,
              video: false,
            });
            ToggleMic(false);
            ToggleVideo(false);
            mediaError(err);
          });
      } else {
        onButtonFlag(false);
      }
    };

    useEffect(() => {
      if (myCUID === participant.cuid) {
        if (participant.stream?.activities.length !== 0) {
          refresh();
        } else {
          onButtonFlag(false);
          refresh();
          ToggleMic(false);
          ToggleVideo(false);
        }
      } else {
        if (participant.stream?.activities.length !== undefined) {
          onRecive();
        } else {
          onNullRecive();
        }
      }
      isUnderPerformance
        ? console.warn(
            `업로드속도가 1.2Mbps 미만으로, 화질이 ${constraints.video.width}p 로 저하됩니다.`,
          )
        : console.log(`화질 복구(${constraints.video.width}p)`);
    }, [
      facingMode,
      participant.uid,
      participant.stream?.activities.length,
      participant.stream,
      participant.updateData,
      isUnderPerformance,
    ]);

    useEffect(() => {
      if (speakerRef.current) {
        if (type === 'full') {
          if (videoFirstCUid === participant.cuid) {
            setSpeaker(3);
            setTimeout(() => {
              setSpeaker(0);
            }, 500);
          }
        } else {
          setSpeaker(3);
          setTimeout(() => {
            setSpeaker(0);
          }, 500);
        }
      } else {
        speakerRef.current = true;
      }
    }, [participant.speaker]);

    const onSend = (videoState: any) => {
      let video: any;

      if (videoView === 'audio') {
        video = document.getElementById('video-' + participant.uid) as HTMLAudioElement;
        if (video) {
          video.srcObject = videoState;
        }
      } else {
        video = document.getElementById('video-' + participant.uid) as HTMLVideoElement;
        if (video) {
          video.srcObject = videoState;
          if (browserName === 'Safari') {
            onButtonFlag(false);
          }
        }
      }

      const opt: any = {
        localVideo: video,
        videoStream: videoState,
        mediaConstraints: constraints,
        onicecandidate: onIceCandidate,
      };
      if (participant.iceServers) {
        opt.configuration = {
          iceServers: toJS(participant.iceServers),
        };
        opt.iceServers = toJS(participant.iceServers);
      }

      peer = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(opt, (error) => {
        if (error) {
          return console.error(error);
        }
        peer.generateOffer(offerToReceiveVideo);
      });
      setPeer && setPeer(participant.cuid, participant.uid, peer, participant.stream?.activities);
      onConnectionstatechange(peer.peerConnection, refresh, participant.cuid, participant.userName);
      video.addEventListener('play', () => {
        onButtonFlag(false);
      });
      if (type === 'full') {
        onButtonFlag(false);
      }
    };

    const onNullRecive = () => {
      const videoElement = videoRef.current;
      if (!videoElement) {
        setTimeout(() => {
          onNullRecive();
        }, 100);
        return;
      }
      if (videoElement) {
        videoElement.srcObject = null;
      }

      const opt: any = {
        remoteVideo: null,
        mediaConstraints: null,
        onicecandidate: onIceCandidate,
        iceServers: participant.iceServers,
      };
      if (participant.iceServers) {
        opt.configuration = {
          iceServers: toJS(participant.iceServers),
        };
        opt.iceServers = toJS(participant.iceServers);
      }

      peer = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(opt, (error) => {
        if (error) {
          console.error(error);
        }
      });
      setPeer && setPeer(participant.cuid, participant.uid, peer, participant.stream?.activities);
      onConnectionstatechange(peer.peerConnection, refresh, participant.cuid, participant.userName);

      const play = videoElement.play();
      if (play !== undefined) {
        play.then(() => videoElement.play()).catch((error: any) => console.log('error', error));
      }
    };

    const onRecive = () => {
      const videoElement = videoRef.current;
      if (!videoElement) {
        setTimeout(() => {
          onRecive();
        }, 100);
        return;
      }

      const opt: any = {
        remoteVideo: videoElement,
        mediaConstraints: constraints,
        onicecandidate: onIceCandidate,
      };
      if (participant.iceServers) {
        opt.configuration = {
          iceServers: toJS(participant.iceServers),
        };
        opt.iceServers = toJS(participant.iceServers);
      }

      peer = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(opt, (error) => {
        if (error) {
          console.error(error);
        }
      });
      peer.generateOffer(offerToReceiveVideo);
      setPeer && setPeer(participant.cuid, participant.uid, peer, participant.stream?.activities);
      onConnectionstatechange(peer.peerConnection, refresh, participant.cuid, participant.userName);
      const play = videoElement.play();
      if (play !== undefined) {
        play.then(() => videoElement.play()).catch((error: any) => console.log('error', error));
      }
      console.log(participant.userName + ' 🌈 ~ 수신 uid : ', participant.uid);
    };

    const offerToReceiveVideo = function (error: any, offerSdp: any) {
      if (error) {
        return console.error('sdp offer error');
      }
      let msg: any = {
        type: 'kurento',
        id: 'broadcastStarted',
        uid: participant.uid,
        sdpOffer: offerSdp,
      };
      if (myCUID !== participant.cuid) {
        msg = {
          type: 'kurento',
          id: 'addListener',
          sender: participant.uid,
          sdpOffer: offerSdp,
        };
      }
      sendMessage(msg);
    };

    const onIceCandidate = (candidate: any, wp: any) => {
      const message = {
        type: 'kurento',
        id: 'onIceCandidate',
        candidate: candidate,
        uid: participant.uid,
        luid: myUID !== participant.uid ? myCUID : participant.uid,
      };
      sendMessage(message);
    };

    const onPinAction = async (cuid: any) => {
      layout && layout(cuid);
    };

    const mediaError = (err: any) => {
      if (err.name === 'NotFoundError' || err === 'DevicesNotFoundError') {
        setNoCam(false);
      }
      const n = err.name;
      rtcAlert(n);
    };

    const rtcAlert = (err: string) => {
      console.log('** rtc alert : ' + participant.userName);
      console.log('** rtc alert : ' + err);
      if (err === 'NotFoundError' || err === 'DevicesNotFoundError') {
        return returnAlertFlag && returnAlertFlag(true, 'meeting.alert.noWebcam');
      } else if (err === 'NotReadableError' || err === 'TrackStartError') {
        return returnAlertFlag && returnAlertFlag(true, 'meeting.alert.webcamInUse');
      } else if (err === 'OverconstrainedError' || err === 'ConstraintNotSatisfiedError') {
        return returnAlertFlag && returnAlertFlag(true, 'meeting.alert.webcamTrack');
      } else if (err === 'NotAllowedError' || err === 'PermissionDeniedError') {
        return returnAlertFlag && returnAlertFlag(true, 'meeting.alert.webcamAccess');
      } else if (err === 'TypeError') {
        return returnAlertFlag && returnAlertFlag(true, 'meeting.alert.mediaTrack');
      } else {
        return 'Unknown error: ' + err;
      }
    };

    const [authCd, setAuthCd] = useState('');
    const AuthIcon = getIconByAuthCd(authCd);
    useEffect(() => {
      // console.log('Log.', 'use effect 2');
      if (participant?.authCd) {
        setAuthCd(toJS(participant.authCd));
      }
    }, [
      participant.uid,
      participant.stream?.activities.length,
      participant.stream,
      participant.updateData,
    ]);
    const onClickEvent = (e: any) => {
      e.target.parentElement.children[0].requestFullscreen();
    };

    return (
      <>
        <Card
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            background: '#000',
            '&:hover': {
              '& .MuiTypography-root, .MuiBox-root': {
                display: 'block',
              },
            },
            boxShadow: '0 0 0 ' + speaker + 'px green',
            borderRadius: isDownMd ? 0 : 2,
          }}
        >
          {videoView !== 'audio' && (
            <Box
              sx={{
                width: videoWidth,
                height: videoHeight,
                maxWidth: 1280,
                // maxHeight: 720,
              }}
              id={participant.uid}
            >
              {(participant.stream?.activities.length === 0 || !participant.stream) && (
                <Stack
                  sx={{
                    width: videoWidth,
                    height: videoHeight,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <AuthIcon />
                </Stack>
              )}
              <video
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onDoubleClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onClickEvent(e);
                }}
                ref={videoRef}
                id={'video-' + participant.uid}
                autoPlay={true}
                playsInline
                style={{
                  width: '100%',
                  height: videoHeight,
                }}
              />
            </Box>
          )}
          {videoView === 'audio' && (
            <Box
              sx={{
                backgroundImage: 'url(/assets/ico-audioImage.svg)',
                backgroundPosition: '50% 50%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '30px',
                width: videoWidth,
                height: videoHeight,
              }}
              id={participant.uid}
            >
              <audio
                ref={videoRef}
                id={'video-' + participant.uid}
                autoPlay={true}
                playsInline
                style={{
                  width: videoWidth,
                  height: videoHeight,
                }}
              />
            </Box>
          )}

          {myCUID === participant?.cuid ? (
            <>
              <Typography
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  p: 2,
                  background: '#0000007a',
                  width: '100%',
                }}
              >
                😀&nbsp;{participant.userName !== '' ? participant.userName : 'guest'}
                <IconButton
                  onClick={() => {
                    refresh();
                  }}
                >
                  <Iconify
                    icon={'grommet-icons:power-reset'}
                    sx={{
                      color: '#999',
                    }}
                  />
                </IconButton>
                {type === 'tile2' && (
                  <IconButton
                    edge="end"
                    aria-label="video-on-off"
                    onClick={() => {
                      onPinAction(participant?.cuid);
                    }}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      right: 0,
                      transform: 'translate(-50%, -50%)',
                      mr: 1,
                    }}
                  >
                    <Iconify
                      icon={'iconoir:pin-solid'}
                      color={pinMode ? theme.palette.primary.main : theme.palette.grey[400]}
                    />
                  </IconButton>
                )}
              </Typography>
            </>
          ) : (
            <>
              {screenMode === true && (
                <IconButton
                  sx={{
                    zIndex: 999,
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    p: 2,
                    color: theme.palette.common.white,
                    // color: theme.palette.primary.main,
                  }}
                  onClick={() => {
                    setOpenScreen(false);
                  }}
                >
                  <Iconify icon={'ic:round-close'} />
                </IconButton>
              )}
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  p: 2,
                  background: '#0000007a',
                  width: '100%',
                }}
              >
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography>
                    {participant.userName !== '' ? participant.userName : 'guest'}
                    <IconButton
                      onClick={() => {
                        onRecive();
                      }}
                    >
                      <Iconify
                        icon={'grommet-icons:power-reset'}
                        sx={{
                          color: '#999',
                        }}
                      />
                    </IconButton>
                  </Typography>
                  {screenMode === false && (
                    <ParticipantsDetail
                      participant={participant}
                      detaildata={detaildata}
                      layout={layout}
                      type={type}
                      pinMode={pinMode}
                    />
                  )}
                </Stack>
              </Box>
            </>
          )}
        </Card>
      </>
    );
  },
);

export default Participant;
