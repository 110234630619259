import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { withEnvironment } from '../extensions/with-environment';
import { IMeeting, Meeting } from '../meeting/Meeting';
import { withRootStore } from '../extensions/with-root-store';
import {
  MeetingApi,
  TDeleteMeetingResult,
  TGetMeetingsResult,
  TPostResult,
  TSaveLogResult,
} from '../../services';
import { IAcceptMeeting, AcceptMeeting } from '../meeting/AcceptMeeting';
import axios from 'axios';

/**
 * # MeetingStore
 *
 * MeetingStore을 설명하세요.
 */
export const MeetingStore = types
  .model('MeetingStore')
  // --------------------------------------------------------------------------
  .props({
    id: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
    meetings: types.array(Meeting),
    responseData: types.optional(types.maybeNull(types.string), ''),
    acceptDatas: types.optional(types.array(AcceptMeeting), []),
    // existingParticipants : types.array(Meeting),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({
    getJoinRoomResponseData: () => {
      return self.responseData;
    },

    getAcceptData: () => {
      return self.acceptDatas;
    },
  }))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * meetings을 교체
     *
     * @param `meetings` 새로운 모델의 배열
     */
    setMeetings: (meetings: IMeeting[]) => {
      self.meetings.replace(meetings);
    },
    setResponseData: (responseData: string) => {
      self.responseData = responseData;
    },

    addAcceptData: (acceptData: IAcceptMeeting) => {
      const item = self.acceptDatas.find((item) => {
        return item.cuid === acceptData.cuid;
      });
      if (!item) {
        self.acceptDatas.replace([...self.acceptDatas, acceptData]);
      }
    },

    removeAcceptData: (cuid: string) => {
      self.acceptDatas.map((acceptData: IAcceptMeeting, index: number) => {
        if (acceptData.cuid === cuid) {
          self.acceptDatas.splice(index, 1);
        }
      });
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * 전체 목록을 Api를 통해 조회
     *
     * 조회한 결과로 Meetings를 교체한다. 실패시 에러 로그를 남긴다.
     */
    gets: async () => {
      const meetingApi: MeetingApi = new MeetingApi(self.environment.api);
      const result: TGetMeetingsResult = await meetingApi.gets();
      if (result.kind === 'ok') {
        self.setMeetings(result.meetings);
      } else {
        console.error(result.kind);
      }
    },

    JoinRoom: async (data: any) => {
      const meetingApi: MeetingApi = new MeetingApi(self.environment.api);
      const result: TPostResult = await meetingApi.JoinRoom(data);

      if (result.kind === 'ok') {
        console.log('result', result.res);
        self.setResponseData(result.res);
      } else if (result) {
        console.error(result.kind);
      }
    },

    removeClient: async (data: any) => {
      const meetingApi: MeetingApi = new MeetingApi(self.environment.api);
      const result: TPostResult = await meetingApi.removeClient(data);

      if (result.kind === 'ok') {
        self.setResponseData(result.res);
      } else {
        console.error(result.kind);
      }
    },

    joinAccept: async (data: any) => {
      const meetingApi: MeetingApi = new MeetingApi(self.environment.api);
      const result: TPostResult = await meetingApi.joinAccept(data);

      if (result.kind === 'ok') {
        self.setResponseData(result.res);
      } else {
        console.error(result.kind);
      }
    },

    joinReject: async (data: any) => {
      const meetingApi: MeetingApi = new MeetingApi(self.environment.api);
      const result: TPostResult = await meetingApi.joinReject(data);

      if (result.kind === 'ok') {
        self.setResponseData(result.res);
      } else {
        console.error(result.kind);
      }
    },

    leaveRoom: async (data: any) => {
      const meetingApi: MeetingApi = new MeetingApi(self.environment.api);
      const result: TDeleteMeetingResult = await meetingApi.leaveRoom(data);

      if (result.kind === 'ok') {
        return { kind: 'ok' };
      } else {
        console.error(result.kind);
      }
    },

    closeRoom: async (data: any) => {
      const meetingApi: MeetingApi = new MeetingApi(self.environment.api);
      const result: TDeleteMeetingResult = await meetingApi.closeRoom(data);

      if (result.kind === 'ok') {
        return { kind: 'ok' };
      } else {
        console.error(result.kind);
      }
    },

    /**
     * 네트워크 다운로드 테스트
     * @param url
     * @param callback
     */
    checkDownloadSpeed: async (url: any, callback: any) => {
      const { REACT_APP_API_URL } = process.env;
      const startTime = new Date().getTime();
      const accessToken = localStorage.getItem('accessToken');
      await axios
        // .get(`${REACT_APP_API_URL}/web/networktest?size=500000`, // 0.5MB -> 1.2MB
        .get(`${REACT_APP_API_URL}/web/networktest?size=1258291`, {
          headers: { Authorization: 'Bearer ' + accessToken },
        })
        .then((r: any) => {
          const endTime = new Date().getTime();
          const duration = (endTime - startTime) / 1000; // seconds
          // const bitsLoaded = 500000 * 8; // 500 KB file in bits
          const bitsLoaded = 1258291 * 8; // 1.2 MB file in bits
          const speedBps = Number((bitsLoaded / duration).toFixed(2));
          const speedKbps = Number((speedBps / 1024).toFixed(2));
          const speedMbps = Number((speedKbps / 1024).toFixed(2));
          callback(speedMbps, r.data);
        })
        .catch((e) => {
          console.error('Error downloading the file', e);
        });
    },

    /**
     * 네트워크 업로드 테스트
     * @param callback
     */
    checkUploadSpeed: async (_data: any, callback: any) => {
      const { REACT_APP_API_URL } = process.env;
      const startTime = new Date().getTime();
      const form = new FormData();
      const file = new File([_data], 'file');
      form.append('file', file);
      axios
        .post(`${REACT_APP_API_URL}/web/networktest`, form, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((res) => {
          const endTime = new Date().getTime();
          const duration = (endTime - startTime) / 1000; // seconds
          const bitsUploaded = file.size;
          const speedBps = Number((bitsUploaded / duration).toFixed(2));
          const speedKbps = Number((speedBps / 1024).toFixed(2));
          const speedMbps = Number((speedKbps / 1024).toFixed(2));
          callback(speedMbps);
        })
        .catch((e) => {
          console.log('🚀 ~ checkUploadSpeed:async ~ e:', e);
        });
    },

    /**
     * 로그저장
     * @param content :기기정보, 네트워크정보, 브라우저정보, 사용자 정보 를 JSON string 형태로
     */
    saveLog: async (content: any) => {
      const jsonString = JSON.stringify(content);
      const meetingApi: MeetingApi = new MeetingApi(self.environment.api);
      const result: TSaveLogResult = await meetingApi.saveLog(jsonString);

      if (result.kind === 'ok') {
        return result;
      } else {
        console.error(result.kind);
      }
    },
  }));

// --------------------------------------------------------------------------
type TMeetingStore = Instance<typeof MeetingStore>;
type TMeetingStoreSnapshot = SnapshotOut<typeof MeetingStore>;

export interface IMeetingStore extends TMeetingStore {}
export type TMeetingStoreKeys = keyof TMeetingStoreSnapshot & string;
export interface IMeetingStoreSnapshot extends TMeetingStoreSnapshot {}
export const createMeetingStore = () => types.optional(MeetingStore, {} as TMeetingStore);
