import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useStores } from '../../../models/root-store/root-store-context';
import {
  AppBar,
  Button,
  Dialog,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Stack,
  styled,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { useLocation } from 'react-router';
import { HEADER } from 'src/config-global';
import { useSettings } from 'src/hooks/useSettings';
import { ReactComponent as IcoViewmodeTile } from 'src/assets/icons/ico-viewmode-tile.svg';
import { ReactComponent as IcoViewmodePin } from 'src/assets/icons/ico-viewmode-pin.svg';
import { ReactComponent as IcoViewmodeFull } from 'src/assets/icons/ico-viewmode-full.svg';
import { ReactComponent as IcoViewmodeMan } from 'src/assets/icons/ico-viewmode-man.svg';
import { ReactComponent as IcoRoomInfo } from 'src/assets/icons/ico-room-info.svg';
import { ReactComponent as IcoPeople } from 'src/assets/icons/ico-people.svg';
import { ReactComponent as IcoRecord } from 'src/assets/icons/ico-record.svg';
import { ReactComponent as IcoChat } from 'src/assets/icons/ico-chat.svg';
import { ReactComponent as IcoWhiteBoard } from 'src/assets/icons/ico-whiteboard.svg';
import { ReactComponent as IcoShareDoc } from 'src/assets/icons/ico-share-doc.svg';
import { ReactComponent as IcoShareScreen } from 'src/assets/icons/ico-share-screen.svg';
import { ReactComponent as IcoWriteReport } from 'src/assets/icons/ico-write-report.svg';
import { IParticipant } from 'src/models/participant/Participant';
import Participant from 'src/screens/home/participant/Participant';
import { useLocales } from 'src/locales';
import ReportCreate from '../ReportCreate';

import { CallApiToStore } from 'src/utils/common';
import Admission from '../Admission';
import { IAcceptMeeting } from 'src/models/meeting/AcceptMeeting';
import GridLayout from 'react-grid-layout';
import _, { isArray, random } from 'lodash';
import { IChatSnapshot, IConferenceSnapshot, IReportSnapshot } from 'src/models';
import { toJS } from 'mobx';
import useResponsive from 'src/hooks/useResponsive';
import MeetingM from '../meeting-m/MeetingM';
import Sidebar from '../sidebar/Sidebar';
import ScreenParticipant from 'src/screens/home/participant/ScreenParticipant';
import WhiteBoard from '../white-board/WhiteBoard';
import Footer from './footer/Footer';
import ModalSetting from '../setting/ModalSetting';
import { useAuthContext } from 'src/auth/useAuthContext';
import Scrollbar from '../../../components/scrollbar';
import RecordParticipant from 'src/screens/home/participant/RecordParticipant';
import DetailPopOver from '../detail-pop-over/DetailPopOver';
import Iconify from 'src/components/iconify';
import Waiting from '../waiting/Waiting';
import { userInfo } from 'os';
import adapter from 'webrtc-adapter';
import { IceServer } from '../../../models/participant/Participant';

/**
 * ## Meeting 설명
 *
 */
let ws: any;
//@ts-ignore
const peers: any = (window.peers = {});
let mediaDevicesInfo: any = null;
let existingParticipants: any;
let addView: any = false;
let lastView: any = 'tile';
const defaultDrawerWidth = 360;
const minDrawerWidth = 240;
const maxDrawerWidth = 720;
let videoMic: any = 0.5;
let videoVol: any = 0.5;
let videoFirstIndex: any = 0;
let videoFirstCUid: any = 0;
let pinMode: boolean = false;
let webSocketStateToken: boolean = false;
let browserName = '알 수 없음';
export const Meeting = observer(() => {
  const rootStore = useStores();
  const {
    conferenceStore,
    participantStore,
    loadingStore,
    meetingStore,
    responseStore,
    errorAlertStore,
  } = rootStore;
  const theme = useTheme();
  const { state } = useLocation();
  const { translate } = useLocales();
  const { REACT_APP_WS_URL, REACT_APP_WS_PING } = process.env;
  const defaultRoom = {
    userId: state.id,
    roomId: state.room.id,
  };
  const [errorMsg, setErrorMsg] = useState('');
  const auth = useAuthContext();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [anchorSet, setAnchorSet] = useState<HTMLButtonElement | null>(null);
  const openSet = Boolean(anchorSet);
  const [conferenceDetail, setConferenceDetail] = useState<HTMLButtonElement | null>(null);
  const openConferenceDetail = Boolean(conferenceDetail);
  const [modalSetting, setModalSetting] = useState<HTMLButtonElement | null>(null);
  const openModalSetting = Boolean(modalSetting);
  const [anchorVideo, setAnchorVideo] = useState<HTMLButtonElement | null>(null);
  const openVideo = Boolean(anchorVideo);
  const [screen, setScreen] = useState();
  const [record, setRecord] = useState<any>();
  const [mobileIndex, setMobileIndex] = useState(0);
  const [mobileChange, setMobileChange] = useState(0);
  const [isRecordAlertOpen, setIsRecordAlertOpen] = useState(false);
  const [noCam, setNoCam] = useState(true);
  const [openScreen, setOpenScreen] = useState(false);
  const [share, setShare] = useState(false);
  const [toggleMic, setToggleMic] = useState(false);
  const [toggleVideo, setToggleVideo] = useState(false);
  const [videoSize, setVideoSize] = useState<'720p' | '360p'>('360p');
  const [sideContent, setSideContent] = useState('');
  const [openSide, setOpenSide] = useState(false);
  const [viewMode, setViewMode] = useState('tile');
  const [layoutType, setLayoutType] = useState(3);
  const [openAdmission, setOpenAdmission] = useState(false);
  const [roomClose, setRoomClose] = useState(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [reReady, setReReady] = useState(false);
  const [connectionSuccess, setConnectionSuccess] = useState(false);
  const [ready, setReady] = useState(false);
  const { themeMode, onChangeThemeMode } = useSettings();
  const [requestRoomAccept, setRequestRoomAccept] = useState('');
  const [requestMessage, setRequestMessage] = useState('N');
  const [stream, setStream] = useState<any>();
  const [audioInput, setAudioInput] = useState<string | null>(null);
  const [audioOutput, setAudioOutput] = useState<string | null>(null);
  const [videoInput, setVideoInput] = useState<string | null>(null);
  const [audioInputs, setAudioInputs] = useState<{ label: string; value: string }[]>([]);
  const [audioOutputs, setAudioOutputs] = useState<{ label: string; value: string }[]>([]);
  const [videoInputs, setVideoInputs] = useState<{ label: string; value: string }[]>([]);
  const [drawerWidth, setDrawerWidth] = useState(defaultDrawerWidth);
  const [whiteboardData, setWhiteboardData] = useState<any>(null);
  const [alertFlag, setAlertFlag] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [meetingMessage, setMeetingMessage] = useState('');

  const [videoFlag, setVideoFlag] = useState(false);
  const [micFlag, setMicFlag] = useState(false);
  const [buttonMicFlag, setButtonMicFlag] = useState(false);
  const [buttonVideoFlag, setButtonVideoFlag] = useState(false);

  const [buttonMicSettingFlag, setButtonMicSettingFlag] = useState(true);
  const [buttonVideoSettingFlag, setButtonVideoSettingFlag] = useState(true);

  const [errorAlertFlag, setErrorAlertFlag] = useState(false);
  const [errorAlertMessage, setErrorAlertMessage] = useState('');

  const [meetingMic, setMeetingMic] = useState(false);
  const [meetingVideo, setMeetingVideo] = useState(false);
  const didMount = useRef(false);
  const [deviceChange, setDeviceChange] = useState(0);
  const [webSocketAlertOpen, setWebSocketAlertOpen] = useState(false);

  const [visibilitychange, setVisibilitychange] = useState(false);
  const [dataPinMode, setDataPinMode] = useState(false);
  const [mobileWidth, setMobileWidth] = useState(0);
  const [mobileHight, setMobileHight] = useState(0);
  const [webSocketAlertMessage, setWebSocketAlertMessage] = useState(
    translate(`meeting.alert.websocket`).toString(),
  );
  const [iceServers, setIceServers] = useState<any>([
    { credential: 'innerwave001', username: 'innerwave', urls: ['turn:101.202.45.75:3478'] },
  ]);

  const [isUnderPerformance, setIsUnderPerformance] = useState(false);

  const websocketUrl = `${REACT_APP_WS_URL}?accessToken=` + localStorage.getItem('accessToken');
  // const websocketUrl = `ws://127.0.0.1:8080/web/groupcall?accessToken=` + localStorage.getItem('accessToken');

  let websocketTimer: any;
  let gridWidth: any;
  let totalHeight: any = window.innerHeight;
  let gridHight: any;
  let fullScreen: any;
  let fullWidth: any;
  const isDownMd = useResponsive('down', 'md');
  const [sideState, setSideState] = useState<any>({
    participant: false,
    share: false,
    chat: false,
  });

  useEffect(() => {
    if (sideState.participant || sideState.share || sideState.chat) {
      setOpenSide(true);
    } else if (!sideState.participant && !sideState.share && !sideState.chat) {
      setOpenSide(false);
    }
  }, [sideState]);

  //뒤로가기 이벤트
  window.addEventListener('popstate', function (event) {
    ws.close();
    if (localStorage.getItem('user') !== null) {
      const userInfo = JSON.parse(localStorage.getItem('user') || '');
      if (
        userInfo.authCd === 'GUEST' ||
        userInfo.authCd === '8' ||
        userInfo.authCd === '9' ||
        userInfo.authCd === null
      ) {
        auth.logout();
        window.location.href = '/login-unprotected';
      } else {
        window.location.href = '/';
      }
    } else {
      auth.logout();
      window.location.href = '/login-unprotected';
    }
  });

  useEffect(() => {
    let root = document.getElementById('root');
    if (openSide && root) {
      gridWidth = root?.clientWidth - 32 - drawerWidth;
    } else if (root) {
      gridWidth = root?.clientWidth - 32;
    }
  }, [openSide]);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(gotDevices);
  }, []);

  const gotDevices = async (deviceInfos: any) => {
    setMediaDevices(deviceInfos);
    const aInputs: any[] = [];
    const aOutputs: any[] = [];
    const vInputs: any[] = [];
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      const option = document.createElement('option');
      option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === 'audioinput') {
        option.text = deviceInfo.label || `microphone ${aInputs.length + 1}`;
        aInputs.push(option);
      } else if (deviceInfo.kind === 'audiooutput') {
        option.text = deviceInfo.label || `speaker ${aOutputs.length + 1}`;
        aOutputs.push(option);
      } else if (deviceInfo.kind === 'videoinput') {
        option.text = deviceInfo.label || `camera ${vInputs.length + 1}`;
        vInputs.push(option);
      } else {
        console.log('Some other kind of source/device: ', deviceInfo);
      }
    }
    if (aInputs.length === 0) {
      setButtonMicSettingFlag(false);
      setButtonMicFlag(true);
    }
    if (vInputs.length === 0) {
      setButtonVideoSettingFlag(false);
      setButtonVideoFlag(true);
    }
  };

  const onNavState = (seq: number) => {
    setNavState(
      (navState: any) =>
        (navState = {
          ...navState,
          ['controlButton_' + seq]: navState['controlButton_' + seq]
            ? !navState['controlButton_' + seq]
            : true,
        }),
    );
  };

  const onShareFiles = () => {
    setSideState({
      ...sideState,
      share: !sideState.share,
    });
  };

  const onViewParticipant = () => {
    setSideState({
      ...sideState,
      participant: !sideState.participant,
    });
  };

  const onViewChat = () => {
    setSideState({
      ...sideState,
      chat: !sideState.chat,
    });
    getChat(state.room.id);
  };

  const [isWhiteboard, setIsWhiteboard] = useState(false);

  const onWhiteBoard = () => {
    setIsWhiteboard((isWhiteboard) => (isWhiteboard = !isWhiteboard));
  };
  const handleConferenceClose = () => {
    setConferenceDetail(null);
  };

  const handleModalSettingClose = (flag: any) => {
    setModalSetting(flag);
  };
  const handleModalSettingOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setModalSetting(event.currentTarget);
    setAnchorSet(event.currentTarget);
  };

  const handleScreenOpen = () => {
    setOpenScreen(!openScreen);
  };

  const onScreenMode = (state: boolean) => {
    setOpenScreen(state);
    setShare(state);
  };

  //화면공유 - 2
  const onShareSelect = (wanna: any, stop: any, seq: any) => {
    const handleSuccess = (stream: any) => {
      const message = {
        type: 'kurento',
        id: wanna,
        shareType: 'screen',
        fps: '10',
      };
      sendMessage(message);
      setScreen(stream);
      stream.getVideoTracks()[0].addEventListener('ended', () => {
        const myScreenUID = sessionStorage.getItem('myScreenUID');
        const message = {
          type: 'kurento',
          id: stop,
          uid: myScreenUID,
        };
        sendMessage(message);
        onScreenMode(false);
        onNavState(seq);
      });
    };
    const handleError = (error: any) => {
      const message = {
        type: 'kurento',
        id: 'errorSharing',
        shareType: 'screen',
        fps: '10',
      };
      sendMessage(message);
      onNavState(seq);
    };
    try {
      const options: any = { audio: false, video: true };
      const displaySurface: string = 'window';
      if (displaySurface !== 'default') {
        options.video = {
          displaySurface,
          // 동영상 해상도 조정
          width: { ideal: 1280 },
          height: { ideal: 720 },
        };
      }
      navigator.mediaDevices.getDisplayMedia(options).then(handleSuccess, handleError);
    } catch (e) {}
  };

  //녹화 기능
  const onRecord = async () => {
    if (data.room.allowRecording === true) {
      if (participantStore.recordParticipant.length === 0) {
        const handleSuccess = (stream: any) => {
          setRecord(stream);
          stream.getVideoTracks()[0].addEventListener('ended', () => {
            const message = {
              type: 'kurento',
              id: 'stopRecord',
            };
            sendMessage(message);
            onNavState(1);
          });
        };
        const handleError = (error: any) => {
          const message = {
            type: 'kurento',
            id: 'stopRecord',
          };
          sendMessage(message);
          onNavState(1);
        };
        try {
          const message = {
            type: 'kurento',
            id: 'wannaRecord',
            shareType: 'record_screen',
            fps: '10',
          };
          sendMessage(message);
          const options: any = { audio: false, video: true };
          const displaySurface: string = 'monitor';
          if (displaySurface !== 'default') {
            options.video = { displaySurface };
          }
          navigator.mediaDevices.getDisplayMedia(options).then(handleSuccess, handleError);
        } catch (e) {}
      } else {
        if (record !== undefined) {
          record.getTracks().forEach((track: any) => {
            track.stop();
          });
          const message = {
            type: 'kurento',
            id: 'stopRecord',
          };
          sendMessage(message);
        }
      }
    } else {
      setAlertFlag(true);
      setAlertMessage(translate(`meeting.alert.nonRecording`).toString());
      onNavState(1);
    }
  };

  const onAlertFlag = (flag: boolean, message: any) => {
    setAlertFlag(flag);
    setAlertMessage(translate(message).toString());
  };

  //화면공유 - 1
  const onShareScreen = () => {
    const myCUID = sessionStorage.getItem('myCUID');
    if (participantStore.screenParticipant.length === 0) {
      onShareSelect('wannaShare', 'pauseSharing', 4);
    } else {
      {
        participantStore.screenParticipant.map((participant: IParticipant, i: number) => {
          if (participant.cuid === myCUID) {
            const myScreenUID = sessionStorage.getItem('myScreenUID');
            const message = {
              type: 'kurento',
              id: 'pauseSharing',
              uid: myScreenUID,
            };
            sendMessage(message);
            onScreenMode(false);
          } else {
            onNavState(4);
            setAlertMessage(translate(`meeting.alert.screenSharing`).toString());
            setAlertFlag(true);
          }
        });
      }
    }
  };

  const onReport = () => {
    getReport(conferenceStore.conference.room.id);
  };

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    var isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i) == null ? false : true;
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i) == null &&
          navigator.maxTouchPoints == 0
          ? false
          : true;
      },
      any: function () {
        return isMobile.Android() || isMobile.iOS();
      },
    };
    if (isMobile.any()) {
      setMobile(true);
    } else {
      setMobile(false);
    }
    const userAgent = window.navigator.userAgent;
    // 브라우저 종류 확인
    if (userAgent.includes('Edg')) {
      browserName = 'Edge';
    } else if (userAgent.includes('Chrome')) {
      browserName = 'Chrome';
    } else if (userAgent.includes('Firefox')) {
      browserName = 'Firefox';
    } else if (userAgent.includes('Safari')) {
      browserName = 'Safari';
    } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
      browserName = 'Opera';
    }
  }, []);

  const onButtonFlag = (flag: any) => {
    if (buttonMicSettingFlag === true) {
      setButtonMicFlag(flag);
    }
    if (buttonVideoSettingFlag === true) {
      setButtonVideoFlag(flag);
    }
  };

  const onToggleMic = () => {
    const myCUID = sessionStorage.getItem('myCUID');
    let itemRights: any;
    participantStore.participants.map((p: IParticipant, i) => {
      if (p.cuid === myCUID) {
        itemRights = p.rights.find((item: any) => {
          return item === 'AUDIO';
        });
      }
    });
    if (itemRights !== undefined) {
      setButtonMicFlag(true);
      setButtonVideoFlag(true);
      if (micFlag) {
        if (toggleMic === true) {
          if (videoStream) {
            videoStream.data.aSrc.disconnect();
          }
        }
        const message = {
          type: 'kurento',
          id: 'toggleActivity',
          activity: 'AUDIO',
        };
        sendMessage(message);
      } else {
      }
    } else {
      // alert('권한없어 너');
    }
  };
  const onToggleVideo = () => {
    const myCUID = sessionStorage.getItem('myCUID');
    let itemRights: any;
    participantStore.participants.map((p: IParticipant, i) => {
      if (p.cuid === myCUID) {
        itemRights = p.rights.find((item: any) => {
          return item === 'VIDEO';
        });
      }
    });
    if (itemRights !== undefined) {
      setButtonMicFlag(true);
      setButtonVideoFlag(true);
      if (videoFlag) {
        const message = {
          type: 'kurento',
          id: 'toggleActivity',
          activity: 'VIDEO',
        };
        sendMessage(message);
      } else {
      }
    } else {
      // alert('권한없어 너');
    }
  };

  window.onresize = function () {
    checkScreenSize();
  };

  const checkScreenSize = () => {
    setTimeout(() => {
      setLayoutType(layoutType + Math.random());
      layoutState.layouts.lg = generateLayout(lastView);
    }, 200);
  };

  const toggleViewMode = (newMode: string) => {
    lastView = newMode;
    const position = document.getElementById('girdBoxId' + videoFirstIndex) as HTMLElement;
    if (newMode === 'tile2') {
      if (position) {
        position.style.position = 'fixed';
      }
    } else {
      if (position) {
        position.style.position = 'absolute';
      }
    }
    setViewMode(newMode);
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView();
    checkScreenSize();
  }, [viewMode]);

  const viewSummary = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (conferenceDetail === null) {
      setConferenceDetail(event.currentTarget);
    } else {
      setConferenceDetail(null);
    }
  };

  useEffect(() => {
    if (didMount.current === false) {
      didMount.current = true;
    } else if (participantStore.participants.length === 0) {
      if (visibilitychange === true) {
        websocketInit('reSet');
        setVisibilitychange(false);
      }
    }
  }, [visibilitychange, participantStore.participants.length]);

  useEffect(() => {
    //앱 , 브라우저 다른탭 이동후 재접속 로직
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState === 'visible') {
        if (!ws || ws.readyState === ws.CLOSED) {
          participantStore.resetParticipants();
          setVisibilitychange(true);
        } else {
          var isMobile = {
            Android: function () {
              return navigator.userAgent.match(/Android/i) == null ? false : true;
            },
            iOS: function () {
              return navigator.userAgent.match(/iPhone|iPad|iPod/i) == null &&
                navigator.maxTouchPoints == 0
                ? false
                : true;
            },
            any: function () {
              return isMobile.Android() || isMobile.iOS();
            },
          };
          if (isMobile.iOS()) {
            const myCUID = sessionStorage.getItem('myCUID');
            participantStore.updateParticipant({
              cuid: myCUID,
              updateData: Math.random(),
            } as IParticipant);
          }
          if (isMobile.Android()) {
            ws.close();
            participantStore.resetParticipants();
            setVisibilitychange(true);
          }
        }
      }
    });

    //와이파이 변경감지
    window.addEventListener('online', () => {
      ws.close();
      participantStore.resetParticipants();
      setVisibilitychange(true);
    });

    document.addEventListener('message', listener);

    //디바이스 변경 감지 이벤트
    navigator.mediaDevices.ondevicechange = (event) => {
      setDeviceChange(Math.random());
      navigator.mediaDevices.enumerateDevices().then(onChangeDevice);
    };

    const check = setInterval(() => {
      if (webSocketStateToken === false) {
        sendMessage({ type: 'ping' });
        //fetch(`${REACT_APP_WS_PING}`, { cache: 'no-store' });
      }
    }, 30000);

    const token = setInterval(() => {
      if (webSocketStateToken === false) {
        tokenReset();
      }
    }, 5 * 60 * 1000);

    websocketCheck();

    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState === 'visible') {
        onChangeThemeMode('dark');
      }
    });

    return () => {
      clearInterval(check);
      clearInterval(token);
      if (ws) {
        console.log('Socket Closed.');
        ws.close();
      }
      leaveRoom();
    };
  }, []);

  const listener = async (event: any) => {
    let data: any;
    try {
      data = JSON.parse(event.data);
    } catch (e) {
      console.log('listener error', event, e);
    }
    if (data.type === 'connectionType') {
      if (data.payload.state === true) {
        ws.close();
        participantStore.resetParticipants();
        setVisibilitychange(true);
      }
    }
  };

  const onChangeDevice = (deviceInfos: any) => {
    const aInputs: any[] = [];
    const aOutputs: any[] = [];
    const vInputs: any[] = [];
    for (let i = 0; i < deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      const option = document.createElement('option');
      option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === 'audioinput') {
        aInputs.push(option);
      } else if (deviceInfo.kind === 'audiooutput') {
        aOutputs.push(option);
      } else if (deviceInfo.kind === 'videoinput') {
        vInputs.push(option);
      } else {
        console.log('Some other kind of source/device: ', deviceInfo);
      }
    }
    let aInputFlag = false;
    let vInputFlag = false;
    let aOutputFlag = false;
    let localaudioInput = localStorage.getItem('audioInput');
    let localvideoInput = localStorage.getItem('videoInput');
    let localaudioOutput = localStorage.getItem('audioOutput');

    for (let i = 0; i < aInputs.length; i++) {
      if (aInputs[i].value === localaudioInput) {
        aInputFlag = true;
      }
    }

    for (let i = 0; i < vInputs.length; i++) {
      if (vInputs[i].value === localvideoInput) {
        vInputFlag = true;
      }
    }

    for (let i = 0; i < aOutputs.length; i++) {
      if (aOutputs[i].value === localaudioOutput) {
        aOutputFlag = true;
      }
    }

    if (!aInputFlag) {
      localStorage.setItem('audioInput', 'default');
      setAudioInput('default');
    }
    if (!vInputFlag) {
      localStorage.setItem('videoInput', 'default');
      setVideoInput('default');
    }
    if (!aOutputFlag) {
      localStorage.setItem('audioOutput', 'default');
      setAudioOutput('default');
    }

    if (mobile) {
      const myCUID = sessionStorage.getItem('myCUID');
      participantStore.updateParticipant({
        cuid: myCUID,
        speaker: Math.random(),
      } as IParticipant);
    }
  };

  const tokenReset = async () => {
    const accessToken = localStorage.getItem('accessToken');
    await fetch(`${REACT_APP_API_URL}/web/communication/ping`, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Accept: 'application/json, text/javascript, */*; q=0.01',
        Authorization: 'Bearer ' + accessToken,
      },
      method: 'POST',
    });
  };

  const reInit = () => {
    sessionStorage.setItem('reInitData', JSON.stringify(defaultRoom));
  };

  const websocketCheck = () => {
    clearTimeout(websocketTimer);
    if (!ws || ws.readyState === ws.CLOSED) {
      websocketInit();
    }
  };
  let websocketState: any;
  const websocketInit = (type?: any) => {
    const flag = ws ? true : false;

    ws = new WebSocket(websocketUrl);
    sessionStorage.setItem('myUID', '');
    sessionStorage.setItem('myCUID', '');
    sessionStorage.setItem('mySessionID', '');

    ws.onopen = function () {
      if (sessionStorage.getItem('reInitData') !== null) {
        console.log('sessionStorage.getItem("reInitData")', sessionStorage.getItem('reInitData'));
        const info = JSON.parse(sessionStorage.getItem('reInitData') || '');
        sessionStorage.removeItem('reInitData');
      }
      if (flag) {
        reInit();
      }
    };

    ws.onmessage = (message: any) => {
      if (isJsonString(message.data)) {
        const parsedMessage = JSON.parse(message.data);

        if (parsedMessage.id) {
          switch (parsedMessage.id) {
            case 'toggleNoActivity':
              toggleNoActivity(parsedMessage);
              break;
            case 'broadcast':
              onBroadcast(parsedMessage);
              break;
            case 'broadcastStopped':
              onBroadcastStopped(parsedMessage);
              break;
            case 'connectionSuccess':
              sessionStorage.setItem('myFirstCUID', parsedMessage.cuid);
              setConnectionSuccess(true);
              if (websocketState !== undefined) {
                setReady(false);
                clearInterval(websocketState);
                webSocketStateToken = false;
              }
              if (type === 'reSet') {
                const myCUID = sessionStorage.getItem('myFirstCUID');
                if (myCUID) {
                  const data = {
                    roomId: state.room.id,
                    cuid: myCUID,
                  };
                  CallApiToStore(meetingStore.JoinRoom(data), 'api', loadingStore)
                    .then((res) => {
                      if (meetingStore.getJoinRoomResponseData() === 'success') {
                        CallApiToStore(conferenceStore.get(state.id), 'api', loadingStore);
                        if (audioInput && videoInput && audioOutput && videoSize) {
                          localStorage.setItem('audioInput', audioInput);
                          localStorage.setItem('videoInput', videoInput);
                          localStorage.setItem('audioOutput', audioOutput);
                          localStorage.setItem('videoSize', videoSize);
                        }
                        initialize(
                          true,
                          audioInput,
                          audioOutput,
                          videoInput,
                          videoSize,
                          toggleMic,
                          toggleVideo,
                          micFlag,
                          videoFlag,
                          audioOutputs,
                          audioInputs,
                          videoInputs,
                        );
                      } else if (meetingStore.getJoinRoomResponseData() === 'wait') {
                        console.log('대기');
                      } else if (meetingStore.getJoinRoomResponseData() === 'room009') {
                        setAlertMessage(translate(`error.room009`).toString());
                        setAlertFlag(true);
                      } else {
                        console.log('조인실패');
                      }
                    })
                    .catch((res) => {
                      console.log('res', res);
                    });
                }
              }
              break;
            case 'updateClientInfo':
              onUpdateClientInfo(parsedMessage);
              break;
            case 'newStream':
              const itemScreen = parsedMessage.stream.activities.find((item: any) => {
                return item === 'SCREEN';
              });
              const itemRecord = parsedMessage.stream.activities.find((item: any) => {
                return item === 'RECORD';
              });
              if (itemScreen === undefined && itemRecord === undefined) {
                if (peers[parsedMessage.stream.cuid]) {
                  peers[parsedMessage.stream.cuid].uid = parsedMessage.stream.uid;
                  participantStore.updateParticipant({
                    id: parsedMessage.stream.uid,
                    uid: parsedMessage.stream.uid,
                    cuid: parsedMessage.stream.cuid,
                    stream: parsedMessage.stream,
                    userName: parsedMessage.stream.user.displayName,
                    micLevel: state.videoMic,
                    updateData: Math.random(),
                    iceServers: parsedMessage.iceServers,
                  } as IParticipant);
                } else {
                  participantStore.addParticipant({
                    id: parsedMessage.stream.uid,
                    uid: parsedMessage.stream.uid,
                    cuid: parsedMessage.stream.cuid,
                    sessionId: parsedMessage.stream.sessionId,
                    stream: parsedMessage.stream,
                    userName: parsedMessage.stream.user.displayName,
                    iceServers: parsedMessage.iceServers,
                  } as IParticipant);
                }
              } else {
                participantStore.addScreenParticipant({
                  id: parsedMessage.stream.uid,
                  uid: parsedMessage.stream.uid,
                  cuid: parsedMessage.stream.cuid,
                  sessionId: parsedMessage.stream.sessionId,
                  stream: parsedMessage.stream,
                  userName: parsedMessage.stream.user.displayName,
                  iceServers: parsedMessage.iceServers,
                } as IParticipant);
                onNavState(4);
                setShare(true);
                onScreenMode(true);
              }
              break;
            case 'newClient':
              if (parsedMessage.client.streams.length === 0) {
                participantStore.addParticipant({
                  id: parsedMessage.client.uid,
                  uid: parsedMessage.client.uid,
                  cuid: parsedMessage.client.cuid,
                  sessionId: parsedMessage.client.sessionId,
                  userName: parsedMessage.client.user.displayName,
                  rights: parsedMessage.client.rights,
                  authCd: parsedMessage.client.user.authCd,
                  iceServers: parsedMessage.iceServers,
                } as IParticipant);
              } else {
                participantStore.addParticipant({
                  id: parsedMessage.client.uid,
                  uid: parsedMessage.client.uid,
                  cuid: parsedMessage.client.cuid,
                  sessionId: parsedMessage.client.sessionId,
                  stream: parsedMessage.stream,
                  userName: parsedMessage.client.user.displayName,
                  rights: parsedMessage.client.rights,
                  authCd: parsedMessage.client.user.authCd,
                  iceServers: parsedMessage.iceServers,
                } as IParticipant);
              }
              if (lastView === undefined) {
                lastView = viewMode;
                layoutState.layouts.lg = generateLayout(viewMode);
              } else {
                layoutState.layouts.lg = generateLayout(lastView);
              }
              break;
            case 'videoResponse':
              Object.keys(peers).map((p: any) => {
                if (peers[p].uid === parsedMessage.uid) {
                  if (peers[p].peer) {
                    peers[p].peer.processAnswer(parsedMessage.sdpAnswer, (error: any) => {
                      if (error) {
                        return console.error('Error adding candidate: ' + error);
                      }
                    });
                  }
                }
              });
              break;
            case 'clientLeave':
              onParticipantLeft(parsedMessage);
              break;
            case 'iceCandidate':
              Object.keys(peers).map((p: any) => {
                if (peers[p].uid === parsedMessage.uid) {
                  if (peers[p].peer) {
                    peers[p].peer.addIceCandidate(parsedMessage.candidate, (error: any) => {
                      if (error) {
                        return console.error('Error adding candidate: ' + error);
                      }
                    });
                  }
                }
              });
              break;
            case 'requestRoomAccept':
              onRequestRoomAccept(parsedMessage);
              break;
            case 'responseRoomAccept':
              setRequestMessage(parsedMessage.isAccept);
              if (parsedMessage.isAccept === 'N') {
                setReReady(true);
              }
              break;
            case 'rights':
              onRights(parsedMessage);
              break;
            case 'noRights':
              noRights(parsedMessage);
              break;
            case 'shareUpdated':
              if (parsedMessage.stream.activities.length === 0) {
                if (parsedMessage.stream.type === 'SCREEN') {
                  participantStore.removeScreenParticipant(parsedMessage.stream.uid);
                } else if (parsedMessage.stream.type === 'RECORD_SCREEN') {
                  participantStore.removeRecordParticipant(parsedMessage.stream.uid);
                }
              }
              break;
            case 'uploadShare':
              state.uploadShare = true;
              onUploadShare(parsedMessage);
              break;
            case 'whiteBoardRes':
              setWhiteboardMessage(JSON.parse(parsedMessage.data));
              break;
            case 'roomClose':
              setAlertMessage(translate(`meeting.alert.ended`).toString());
              setAlertFlag(true);
              setRoomClose(true);
              break;
            case 'presenter':
              if (pinMode === false) {
                const index = participantStore.participants.map((p: IParticipant, i) => {
                  if (p.cuid === parsedMessage.client.cuid) {
                    return i;
                  }
                });
                const videoIndex = index.find((item: any) => {
                  return item !== undefined;
                });
                setLayoutType(layoutType + Math.random());
                if (videoIndex) {
                  setMobileIndex(videoIndex);
                }
                setMobileChange(Math.random());
                videoFirstIndex = videoIndex;
                videoFirstCUid = parsedMessage.client.cuid;
                layoutState.layouts.lg = generateLayout(lastView, videoIndex);
              }
              break;
            case 'myClientInfo':
              if (parsedMessage.client.user) {
                const participant: IParticipant = {
                  id: parsedMessage.client.uid,
                  uid: parsedMessage.client.uid,
                  cuid: parsedMessage.client.cuid,
                  rights: parsedMessage.client.rights,
                  userName: parsedMessage.client.user.displayName,
                } as IParticipant;
                participantStore.addParticipant(participant);
              } else {
                const participant: IParticipant = {
                  id: parsedMessage.client.uid,
                  uid: parsedMessage.client.uid,
                  cuid: parsedMessage.client.cuid,
                  rights: parsedMessage.client.rights,
                } as IParticipant;
                participantStore.addParticipant(participant);
              }
              break;
            case 'speaker':
              onSpeaker(parsedMessage);
              break;
            case 'maxVideoInRoom':
              setAlertFlag(true);
              setAlertMessage(translate(`meeting.alert.videoMaximum`).toString());
              onMaxVideoInRoom(parsedMessage);
              break;
            case 'compulsionLogin':
              // setErrorAlertFlag(true);
              // setErrorAlertMessage(translate(`error.` + parsedMessage.error).toString());
              responseStore.setResponseInfo(parsedMessage);
              errorAlertStore.setErrorAlertFromResponse();
              break;
            default:
              console.error('Unrecognized message', parsedMessage);
          }
        } else {
          switch (parsedMessage.type) {
            case 'chat': //채팅
              if (parsedMessage) {
                if (parsedMessage.msg) {
                  setNewChatMsg({
                    ...parsedMessage.msg,
                    self: parsedMessage.self,
                    message: parsedMessage.msg.message,
                  });
                }
              }
              break;
            default:
              console.error('Unrecognized message', parsedMessage);
          }
        }
      }
    };
    ws.onclose = function () {
      websocketState = setInterval(() => {
        if (ws.readyState === 3) {
          if (participantStore.participants.length === 0) {
            websocketInit();
          } else {
            webSocketStateToken = true;
            participantStore.resetParticipants();
            sessionStorage.setItem('myFirstCUID', '');
            setWebSocketAlertOpen(true);
            setWebSocketAlertMessage(translate(`meeting.alert.websocket`).toString());
          }
        }
      }, 5000);
      console.log('websocket close');
    };
  };

  //문서 업로드시 문서 공유 리스트 업데이트
  const onUploadShare = async (request: any) => {
    await CallApiToStore(conferenceStore.get(state.id), 'api', loadingStore).then(() => {});
  };

  const [chatMsg, setChatMsg] = useState<IChatSnapshot[]>([]);
  const [newChatMsg, setNewChatMsg] = useState<IChatSnapshot>({} as IChatSnapshot);

  // 이전채팅 조회
  const getChat = async (roomId: number) => {
    await CallApiToStore(conferenceStore.getChat(roomId), 'api', loadingStore).then(() => {
      setChatMsg([]);
      setChatMsg(toJS(conferenceStore.chat));
    });
  };

  const onRequestRoomAccept = (request: any) => {
    if (state.room.id === request.tryRoomId) {
      const acceptData: IAcceptMeeting = {
        roomId: request.tryRoomId,
        cuid: request.targetCuid,
        displayName: request.targetDisplayName,
        email: request.targetEmail,
        isMember: request.isMember,
        authCd: request.targetClientAuthCd,
      } as IAcceptMeeting;
      meetingStore.addAcceptData(acceptData);
      setRequestRoomAccept(state.room.id);
      setOpenAdmission(true);
    }
  };

  const { REACT_APP_API_URL } = process.env;
  //기존 방 입장 유저 정보 api
  const UserInfo = async (cuid: String, waitingMic: boolean, waitingVideo: boolean) => {
    const roomId: String = state.room.id;
    const data = {
      roomId: roomId,
      cuid: cuid,
    };
    const accessToken = localStorage.getItem('accessToken');
    const response = await fetch(`${REACT_APP_API_URL}/web/room/getUsers`, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        Accept: 'application/json, text/javascript, */*; q=0.01',
        Authorization: 'Bearer ' + accessToken,
      },
      method: 'POST',
      body: JSON.stringify(data),
    });
    const jsonData = await response.json();
    existingParticipants = jsonData;
    sendMySettings('setting', waitingMic, waitingVideo);
  };

  const isJsonString = (str: any) => {
    try {
      const json = JSON.parse(str);
      return typeof json === 'object';
    } catch (e) {
      return false;
    }
  };

  //모든 권한 x
  const noRights = (msg: any) => {
    participantStore.updateParticipant({
      id: msg.cuid,
      uid: msg.cuid,
      cuid: msg.cuid,
      stream: null,
    } as IParticipant);
  };

  //한가지 권한( 오디오 , 비디오 )
  const onRights = (msg: any) => {
    if (msg.client.streams.length === 0) {
      participantStore.updateParticipant({
        id: msg.client.uid,
        uid: msg.client.uid,
        cuid: msg.client.cuid,
        userName: msg.client.user.displayName,
        stream: null,
        rights: msg.client.rights,
      } as IParticipant);
    } else {
      participantStore.updateParticipant({
        id: msg.client.streams[0].uid,
        uid: msg.client.streams[0].uid,
        cuid: msg.client.streams[0].cuid,
        userName: msg.client.user.displayName,
        stream: msg.client.streams[0],
        micLevel: videoVol,
        rights: msg.client.rights,
      } as IParticipant);
    }
  };

  //장치 상세 설정 적용
  useEffect(() => {
    state.audioInput = audioInput;
    state.videoInput = videoInput;
    state.audioOutput = audioOutput;
    state.videoSize = videoSize;
    if (localStorage.getItem('user') !== null) {
      const userInfo = JSON.parse(localStorage.getItem('user') || '');
      if (
        userInfo.authCd === 'GUEST' ||
        userInfo.authCd === '8' ||
        userInfo.authCd === '9' ||
        userInfo.authCd === null
      ) {
      } else {
        onSaveEnv();
      }

      const video: any = document.querySelectorAll('video');
      for (let i = 0; video.length > i; i++) {
        if (i > 0) {
          if (typeof video[i].sinkId !== 'undefined' && audioOutput !== null) {
            video[i]
              .setSinkId(audioOutput)
              .then(() => {
                console.log(`Success, audio output device attached: ${audioOutput}`);
              })
              .catch((error: any) => {
                let errorMessage = error;
                if (error.name === 'SecurityError') {
                  errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                }
                console.error(errorMessage);
                setAudioInput('');
              });
          } else {
            console.warn('Browser does not support output device selection.');
          }
        }
      }
    }
  }, [audioInput, videoInput, audioOutput, videoSize, state.videoMic, videoVol]);

  //장치 설정 세팅
  const sendMySettings = (type: any, waitingMic?: boolean, waitingVideo?: boolean) => {
    let activityType: any = '';
    if (type === 'setting') {
      if (waitingMic === true) {
        activityType = 'AUDIO';
        if (waitingVideo === true) {
          activityType = 'AUDIO_VIDEO';
        }
      } else if (waitingVideo === true) {
        activityType = 'VIDEO';
      }
    }
    if (state.videoMic !== undefined) {
      videoMic = state.videoMic;
    }
    var setMessage = {
      type: 'setClient',
      area: 'room',
      settings: {
        chat: {
          muted: false,
          sendOn: 'ctrl',
        },
        video: {
          activity: activityType,
          cam: 0,
          height: 360,
          mic: 0,
          speaker: -1,
          speakerDevice: -1,
          width: 640,
        },
        level: {
          micLevel: videoMic,
          volLevel: videoVol,
        },
      },
    };
    sendMessage(setMessage);
  };

  const toggleNoActivity = (msg: any) => {
    Object.keys(msg.clientList).map((p: any) => {
      if (msg.clientList[p].activities.length === 0) {
        participantStore.updateParticipant({
          id: msg.clientList[p].uid,
          uid: msg.clientList[p].uid,
          cuid: msg.clientList[p].cuid,
          userName: msg.clientList[p].user.displayName,
          stream: null,
        } as IParticipant);
      }
    });
  };

  //유저 스트림 정보 업데이트
  const onUpdateClientInfo = (msg: any) => {
    // console.log('Log.', '유저 스트림 정보 업데이트');
    const myCUID = sessionStorage.getItem('myCUID');
    sessionStorage.setItem('myUID', msg.updateClient.uid);
    sessionStorage.setItem('myCUID', msg.updateClient.cuid);
    sessionStorage.setItem('mySessionID', msg.updateClient.sessionId);
    if (myCUID === msg.updateClient.cuid) {
      if (msg.updateClient.streams.length === 0) {
        participantStore.updateParticipant({
          id: msg.updateClient.streams[0].uid,
          uid: msg.updateClient.streams[0].uid,
          cuid: msg.updateClient.streams[0].cuid,
          userName: msg.updateClient.user.displayName,
          stream: null,
          rights: msg.updateClient.rights,
          micLevel: state.videoMic,
          updateData: Math.random(),
          iceServers: msg.iceServers,
        } as IParticipant);
      } else {
        participantStore.updateParticipant({
          id: msg.updateClient.streams[0].uid,
          uid: msg.updateClient.streams[0].uid,
          cuid: msg.updateClient.streams[0].cuid,
          userName: msg.updateClient.user.displayName,
          stream: msg.updateClient.streams[0],
          rights: msg.updateClient.rights,
          micLevel: state.videoMic,
          volLeve: videoVol,
          updateData: Math.random(),
          iceServers: msg.iceServers,
        } as IParticipant);
      }
    } else {
      const participant: IParticipant = {
        id: msg.updateClient.uid,
        uid: msg.updateClient.uid,
        cuid: msg.updateClient.cuid,
        userName: msg.updateClient.user.displayName,
        rights: msg.updateClient.rights,
        stream: null,
        micLevel: state.videoMic,
        iceServers: msg.iceServers,
      } as IParticipant;
      participantStore.addParticipant(participant);

      onExistingParticipants();
    }
    addView = !addView;
  };

  const onMaxVideoInRoom = (msg: any) => {
    const myCUID = sessionStorage.getItem('myFirstCUID');
    if (myCUID) {
      sessionStorage.setItem('myCUID', myCUID);
    }
    if (participantStore.participants.length < 2) {
      onExistingParticipants();
    }
    setButtonMicFlag(false);
    setButtonVideoFlag(false);
  };

  //스트림 정보 Broadcast
  const onBroadcast = (msg: any) => {
    // console.log("🚀 ~ 브로드캐스트 ~ msg:", msg)
    const myCUID = sessionStorage.getItem('myCUID');
    const itemScreen = msg.stream.activities.find((item: any) => {
      return item === 'SCREEN';
    });
    const itemRecord = msg.stream.activities.find((item: any) => {
      return item === 'RECORD';
    });
    if (myCUID === '') {
      sessionStorage.setItem('myUID', msg.stream.uid);
      sessionStorage.setItem('myCUID', msg.stream.cuid);
      sessionStorage.setItem('mySessionID', msg.stream.sessionId);
      participantStore.updateParticipant({
        id: msg.stream.uid,
        uid: msg.stream.uid,
        cuid: msg.stream.cuid,
        stream: msg.stream,
        userName: msg.stream.user.displayName,
        micLevel: state.videoMic,
        authCd: msg.stream.user.authCd,
        iceServers: msg.iceServers,
      } as IParticipant);
      if (
        state.room.allowRecording === true &&
        state.ownerYn === 'Y' &&
        isRecordAlertOpen === false
      ) {
        setIsRecordAlertOpen(true);
      }
      if (msg.iceServers) {
        setIceServers(msg.iceServers);
      }
      onExistingParticipants();
    } else {
      if (itemScreen === undefined && itemRecord === undefined) {
        sessionStorage.setItem('myUID', msg.stream.uid);
        sessionStorage.setItem('myCUID', msg.stream.cuid);
        sessionStorage.setItem('mySessionID', msg.stream.sessionId);
        const participant2: IParticipant = {
          id: msg.stream.uid,
          uid: msg.stream.uid,
          cuid: msg.stream.cuid,
          stream: msg.stream,
          userName: msg.stream.user.displayName,
          micLevel: state.videoMic,
          authCd: msg.stream.user.authCd,
          iceServers: msg.iceServers,
        } as IParticipant;
        participantStore.participants.map((p: IParticipant) => {
          if (p.cuid === msg.stream.cuid) {
            participantStore.updateParticipant({
              ...participant2,
              isLive: true,
            });
          }
        });
      } else {
        if (itemScreen !== undefined) {
          sessionStorage.setItem('myScreenUID', msg.stream.uid);
          const screenParticipant: IParticipant = {
            id: msg.stream.uid,
            uid: msg.stream.uid,
            cuid: msg.stream.cuid,
            stream: msg.stream,
            userName: '공유중',
            micLevel: state.videoMic,
            authCd: msg.stream.user.authCd,
            iceServers: msg.iceServers,
          } as IParticipant;
          participantStore.addScreenParticipant(screenParticipant);
        }
        if (itemRecord !== undefined) {
          const recordParticipant: IParticipant = {
            id: msg.stream.uid,
            uid: msg.stream.uid,
            cuid: msg.stream.cuid,
            stream: msg.stream,
            userName: '녹화중',
            micLevel: state.videoMic,
            authCd: msg.stream.user.authCd,
            iceServers: msg.iceServers,
          } as IParticipant;
          participantStore.addRecordParticipant(recordParticipant);
        }
      }
    }
  };

  const onSpeaker = (msg: any) => {
    participantStore.participants.map((participant: IParticipant, i: number) => {
      if (msg.client.cuid === participant.cuid) {
        participantStore.updateParticipant({
          cuid: participant.cuid,
          speaker: Math.random(),
        } as IParticipant);
      }
    });
  };

  const [screenFlag, setScreenFlag] = useState(false);
  useEffect(() => {
    setScreenFlag(!screenFlag);
  }, [screen]);

  //방에 입장해 있는 유저 등록
  const onExistingParticipants = () => {
    if (existingParticipants) {
      Object.keys(existingParticipants.responseData.existingParticipants).map((p: any) => {
        if (existingParticipants.responseData.existingParticipants[p] !== 0) {
          // console.log('* 기존 참여자.', existingParticipants.responseData.existingParticipants[p]);
          //스트림 정보가 없을때(비디오 , 오디오 OFF)
          if (existingParticipants.responseData.existingParticipants[p].streams.length === 0) {
            const participant: IParticipant = {
              id: existingParticipants.responseData.existingParticipants[p].uid,
              uid: existingParticipants.responseData.existingParticipants[p].uid,
              cuid: existingParticipants.responseData.existingParticipants[p].cuid,
              userName: existingParticipants.responseData.existingParticipants[p].user.displayName,
              rights: existingParticipants.responseData.existingParticipants[p].rights,
              authCd: existingParticipants.responseData.existingParticipants[p].user.authCd,
              iceServers: existingParticipants.responseData.existingParticipants[p].iceServers,
            } as IParticipant;
            participantStore.addParticipant(participant);
          } else {
            //스트림이 있을때(비디오 , 오디오 ON)
            for (
              let i = 0;
              existingParticipants.responseData.existingParticipants[p].streams.length > i;
              i++
            ) {
              const itemScreen = existingParticipants.responseData.existingParticipants[p].streams[
                i
              ].activities.find((item: any) => {
                return item === 'SCREEN';
              });
              const itemRecord = existingParticipants.responseData.existingParticipants[p].streams[
                i
              ].activities.find((item: any) => {
                return item === 'RECORD';
              });
              //화면공유 , 녹화 둘다 OFF
              if (itemScreen === undefined && itemRecord === undefined) {
                const participant: IParticipant = {
                  id: existingParticipants.responseData.existingParticipants[p].streams[i].uid,
                  uid: existingParticipants.responseData.existingParticipants[p].streams[i].uid,
                  cuid: existingParticipants.responseData.existingParticipants[p].streams[i].cuid,
                  userName:
                    existingParticipants.responseData.existingParticipants[p].user.displayName,
                  stream: existingParticipants.responseData.existingParticipants[p].streams[i],
                  rights: existingParticipants.responseData.existingParticipants[p].rights,
                  authCd: existingParticipants.responseData.existingParticipants[p].user.authCd,
                  iceServers: existingParticipants.responseData.existingParticipants[p].iceServers,
                } as IParticipant;
                participantStore.addParticipant(participant);
              }
              //화면공유 ON
              if (itemScreen !== undefined) {
                onNavState(4);
                onScreenMode(true);
                if (
                  existingParticipants.responseData.existingParticipants[p].streams.length === 1
                ) {
                  const Participant: IParticipant = {
                    id: existingParticipants.responseData.existingParticipants[p].streams[i].cuid,
                    uid: existingParticipants.responseData.existingParticipants[p].streams[i].cuid,
                    cuid: existingParticipants.responseData.existingParticipants[p].streams[i].cuid,
                    userName:
                      existingParticipants.responseData.existingParticipants[p].user.displayName,
                    stream: null,
                    rights: existingParticipants.responseData.existingParticipants[p].rights,
                    authCd: existingParticipants.responseData.existingParticipants[p].user.authCd,
                    iceServers:
                      existingParticipants.responseData.existingParticipants[p].iceServers,
                  } as IParticipant;
                  participantStore.addParticipant(Participant);
                }
                const ScreenParticipant: IParticipant = {
                  id: existingParticipants.responseData.existingParticipants[p].streams[i].uid,
                  uid: existingParticipants.responseData.existingParticipants[p].streams[i].uid,
                  cuid: existingParticipants.responseData.existingParticipants[p].streams[i].cuid,
                  userName:
                    existingParticipants.responseData.existingParticipants[p].user.displayName,
                  stream: existingParticipants.responseData.existingParticipants[p].streams[i],
                  rights: existingParticipants.responseData.existingParticipants[p].rights,
                  authCd: existingParticipants.responseData.existingParticipants[p].user.authCd,
                  iceServers: existingParticipants.responseData.existingParticipants[p].iceServers,
                } as IParticipant;
                participantStore.addScreenParticipant(ScreenParticipant);
              }
            }
          }
        }
      });
    }
    layoutState.layouts.lg = generateLayout(viewMode);
  };

  //기존 스트림 정지
  const onBroadcastStopped = (message: any) => {
    if (peers[message.uid]) {
      peers[message.uid].peer.dispose();
      const myCUID = sessionStorage.getItem('myCUID') || '';

      participantStore.screenParticipant.map((participant: IParticipant, i: number) => {
        if (participant.uid === message.uid) {
          if (participant.cuid !== myCUID) {
            onNavState(4);
          }
        }
      });
      participantStore.removeScreenParticipant(message.uid);
      participantStore.removeRecordParticipant(message.uid);
      participantStore.participants.map((participant: IParticipant, i: number) => {
        const itemScreen = participant.stream?.activities.find((item: any) => {
          return item === 'SCREEN';
        });
        if (itemScreen !== undefined) {
          onScreenMode(true);
        } else {
          onScreenMode(false);
        }
      });
    }
  };
  //마이크 on/off 설정
  const handleToggleMic = (message: any) => {
    setToggleMic(message);
  };
  //오디오 on/off 설정
  const handleToggleVideo = (message: any) => {
    setToggleVideo(message);
  };

  //방 퇴장
  const leaveRoom = async () => {
    if (participantStore.screenParticipant.length !== 0) {
      participantStore.screenParticipant.map((screenParticipant: IParticipant, i: number) => {
        const myCUID = sessionStorage.getItem('myCUID');
        if (myCUID === screenParticipant.stream?.cuid) {
          const myScreenUID = sessionStorage.getItem('myScreenUID');
          const sharingMessage = {
            type: 'kurento',
            id: 'pauseSharing',
            uid: myScreenUID,
          };
          sendMessage(sharingMessage);
        } else {
          participantStore.removeLeaveScreenParticipant();
        }
      });
    }

    if (participantStore.recordParticipant.length !== 0) {
      participantStore.recordParticipant.map((recordParticipant: IParticipant, i: number) => {
        const myCUID = sessionStorage.getItem('myCUID');
        if (myCUID === recordParticipant.stream?.cuid) {
          const myScreenUID = sessionStorage.getItem('myScreenUID');
          const recordMessage = {
            type: 'kurento',
            id: 'stopRecord',
            uid: myScreenUID,
          };
          sendMessage(recordMessage);
        }
      });
    }

    const myCUID = sessionStorage.getItem('myCUID') || '';
    if (myCUID !== '') {
      sessionStorage.removeItem('reInitData');
      const data = {
        cuid: myCUID,
      };
      await CallApiToStore(meetingStore.leaveRoom(data), 'api', loadingStore)
        .then((data) => {
          if (stream) {
            stream.getTracks().forEach((track: any) => {
              track.stop();
            });
          }
          const video = document.querySelector('video');
          if (video) {
            video.srcObject = null;
          }
          ws.close();
          const userInfo = JSON.parse(localStorage.getItem('user') || '');
          if (
            userInfo.authCd === 'GUEST' ||
            userInfo.authCd === '8' ||
            userInfo.authCd === '9' ||
            userInfo.authCd === null
          ) {
            auth.logout();
            window.location.href = '/login-unprotected';
          } else {
            window.location.href = '/';
          }
        })
        .catch((res) => {
          console.log('res', res);
        });
      // }
    }
  };

  //본인제외 다른 유저 방 퇴장
  const onParticipantLeft = (request: any) => {
    // eslint-disable-next-line no-lone-blocks
    {
      participantStore.participants.forEach((participant: IParticipant, i: number) => {
        if (participant.cuid === request.uid) {
          if (request.uid === videoFirstCUid) {
            videoFirstIndex = 0;
            scrollRef.current?.scrollIntoView();
            checkScreenSize();
          } else {
            if (videoFirstIndex !== 0) {
              if (i < videoFirstIndex) {
                videoFirstIndex = videoFirstIndex - 1;
              }
            }
            scrollRef.current?.scrollIntoView();
            checkScreenSize();
          }
        }
      });
    }
    if (peers[request.uid] !== undefined) {
      peers[request.uid].peer.dispose();
      participantStore.removeParticipant(request.uid);
    }
  };

  const onConnectionstatechange = (
    peerConnection: RTCPeerConnection,
    _refresh?: any,
    participantCUID?: string,
    userName?: string,
  ) => {
    peerConnection.onconnectionstatechange = async (evt: any) => {
      // console.log('1', peerConnection.connectionState);
      console.log('peerConnection.onconnectionstatechange', peerConnection);
      switch (peerConnection.connectionState) {
        case 'connected':
          console.log('% Connection to Media server has been established');
          break;
        case 'disconnected':
          console.log('% disconnected');
          break;
        case 'failed':
          console.error('% Connection failed : ' + userName);
          console.error('% Media server connection for user will try to re-connect : ' + userName);
          if (_refresh) {
            // _refresh(participantCUID);
          }
          break;
        default:
          console.info('peerConnection.connectionState', peerConnection.connectionState);
          break;
      }
    };
    const maxBitrateInBitsPerSecond = 75000;
    const senders = peerConnection.getSenders();
    senders.forEach((sender) => {
      if (sender.track?.kind === 'audio') {
        const parameters = sender.getParameters();
        if (!parameters.encodings) {
          parameters.encodings = [{}];
        }
        parameters.encodings[0].maxBitrate = maxBitrateInBitsPerSecond;
        sender
          .setParameters(parameters)
          .then(() => {
            console.log('Bitrate changed successfuly');
          })
          .catch((e) => console.error(e));
      }
      // if (sender.track?.kind === ‘audio’){
      //     // Change bitrate for audio track here
      // }
    });
  };

  const sendMessage = (message: any) => {
    const jsonMessage = JSON.stringify(message);
    if (ws && ws.readyState === 1) {
      ws.send(jsonMessage);
    } else {
      console.log('ws send failed', message);
    }
  };

  const addPeer = (cuid: string, uid: string, peer: any, stream: any) => {
    let screenPeer: any;
    let recordPeer: any;
    if (stream) {
      for (let i = 0; stream.length > i; i++) {
        if (stream[i] === 'SCREEN') {
          screenPeer = stream[i];
        }
        if (stream[i] === 'RECORD') {
          recordPeer = stream[i];
        }
      }
      if (screenPeer === 'SCREEN') {
        peers[uid] = {
          uid: uid,
          peer: peer,
        };
      } else {
        peers[cuid] = {
          uid: uid,
          peer: peer,
        };
      }
    } else {
      peers[cuid] = {
        uid: uid,
        peer: peer,
      };
    }
  };

  const addScreenPeer = (cuid: string, uid: string, peer: any, reset: any) => {
    peers[uid] = {
      uid: uid,
      peer: peer,
    };
  };

  const addRecordPeer = (cuid: string, uid: string, peer: any, reset: any) => {
    peers[uid] = {
      uid: uid,
      peer: peer,
    };
  };

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
          resizeObserverErr.remove();
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
          resizeObserverErrDiv.remove();
        }
      }
    });
  }, []);

  const onAdmissionClear = (message: any) => {
    setOpenAdmission(message);
  };

  //환경설정 정보 저장
  const returnVideoValue = async (
    audioInput: any,
    audioOutput: any,
    videoInput: any,
    videoSize: any,
    settingVideoMic: any,
    settingVideoVol: any,
  ) => {
    setVideoSize(videoSize);
    setAudioInput(audioInput);
    setAudioOutput(audioOutput);
    setVideoInput(videoInput);
    localStorage.setItem('audioInput', audioInput);
    localStorage.setItem('videoInput', videoInput);
    localStorage.setItem('audioOutput', audioOutput);
    localStorage.setItem('videoSize', videoSize);
    videoMic = settingVideoMic;
    state.videoMic = settingVideoMic;
    videoVol = settingVideoVol;
    state.videoVol = settingVideoVol;
    const userInfo = JSON.parse(localStorage.getItem('user') || '');
    if (
      userInfo.authCd === 'GUEST' ||
      userInfo.authCd === '8' ||
      userInfo.authCd === '9' ||
      userInfo.authCd === null
    ) {
    } else {
      onSaveEnv();
    }
    sendMySettings('reSetting');
    let VideoVol = document.querySelectorAll('video');
    if (VideoVol.length > 0) {
      for (let i = 0; VideoVol.length > i; i++) {
        if (i > 0) {
          if (i < VideoVol.length) {
            VideoVol[i].volume = videoVol;
          }
        }
      }
    }
    let AudioVol = document.querySelectorAll('audio-');
    if (AudioVol.length > 0) {
      for (let i = 0; AudioVol.length > i; i++) {
        if (i > 0) {
          if (i < VideoVol.length) {
            VideoVol[i].volume = videoVol;
          }
        }
      }
    }
  };

  //대기실에서 미팅룸 입장
  const initialize = async (
    message: any,
    audioInput: any,
    audioOutput: any,
    videoInput: any,
    videoSize: any,
    waitingMic: any,
    waitingVideo: any,
    waitingMicFlag: any,
    waitingVideoFlag: any,
    audioOutputs: any,
    audioInputs: any,
    videoInputs: any,
  ) => {
    var initMessage = {
      type: 'initClient',
    };
    sendMessage(initMessage);
    const myCUID = sessionStorage.getItem('myFirstCUID');
    if (myCUID) {
      UserInfo(myCUID, waitingMic, waitingVideo);
    }
    setMeetingMessage(message);
    setMeetingMic(waitingMic);
    setMeetingVideo(waitingVideo);
    setVideoFlag(waitingMicFlag);
    setMicFlag(waitingVideoFlag);
    if (state.ownerYn === 'Y') {
      const { REACT_APP_API_URL } = process.env;
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${REACT_APP_API_URL}/web/env`, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Accept: 'application/json, text/javascript, */*; q=0.01',
          Authorization: 'Bearer ' + accessToken,
        },
        method: 'GET',
      });
      const jsonData = await response.json();
      responseStore.setResponseInfo(jsonData);
      setAudioInput(jsonData.responseData.deviceAudioId);
      setVideoInput(jsonData.responseData.deviceVideoId);
      setAudioOutput(jsonData.responseData.deviceSpeakerId);
      setVideoSize(jsonData.responseData.resolution);
      localStorage.setItem('audioInput', jsonData.responseData.deviceAudioId);
      localStorage.setItem('videoInput', jsonData.responseData.deviceVideoId);
      localStorage.setItem('audioOutput', jsonData.responseData.deviceSpeakerId);
      localStorage.setItem('videoSize', jsonData.responseData.resolution);
      setAudioOutputs(audioOutputs);
      state.videoMic = jsonData.responseData.micLevel;
      state.videoVol = jsonData.responseData.volLevel;
      videoMic = jsonData.responseData.micLevel;
      videoVol = jsonData.responseData.volLevel;
      setReady(message);
    } else {
      setAudioInput(audioInput);
      setAudioOutput(audioOutput);
      setVideoInput(videoInput);
      setVideoSize(videoSize);
      setVideoInputs(videoInputs);
      setAudioInputs(audioInputs);
      setAudioOutputs(audioOutputs);
      setReady(message);
    }
  };
  //사용자 기기설정 저장(회원만)
  const onSaveEnv = async () => {
    let data: any = {
      resolution: videoSize,
      micLevel: videoMic,
      volLevel: videoVol,
    };
    if (audioInput && videoInput) {
      data['deviceAudioId'] = audioInput;
      data['deviceVideoId'] = videoInput;
      data['deviceSpeakerId'] = audioOutput;

      const accessToken = localStorage.getItem('accessToken');
      await fetch(`${REACT_APP_API_URL}/web/env`, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Accept: 'application/json, text/javascript, */*; q=0.01',
          Authorization: 'Bearer ' + accessToken,
        },
        method: 'POST',
        body: JSON.stringify(data),
      });
    }
  };
  //스트림 변경시 기존 스트림 제거위해 스트림 저장
  const handStream = (stream: any) => {
    setStream(stream);
  };

  const [videoStream, setVideoStream] = useState<any>();

  //말하는 사람 확인 (비디오 설정(0~1) * 10 * 마이크 소리(0~1))
  const handVideoStream = async (videoStream: any) => {
    if (videoStream.data.aCtx) {
      await videoStream.data.aCtx.audioWorklet.addModule('/assets/volume-meter-processor.js');
      const volumeMeterNode = new AudioWorkletNode(videoStream.data.aCtx, 'volume-meter');
      volumeMeterNode.port.onmessage = ({ data }) => {
        if (data * 10 * videoMic > 1) {
          const message = {
            type: 'mic',
            vol: (data * 10 * videoMic).toFixed(1),
          };
          sendMessage(message);
        }
      };
      videoStream.data.aSrc.connect(volumeMeterNode).connect(videoStream.data.aCtx.destination);
    }
    setVideoStream(videoStream);
  };
  const [layoutState, setLayoutState] = useState({
    currentBreakpoint: 'lg',
    compactType: 'vertical',
    mounted: true,
    breakpoints: 'lg',
    layouts: { lg: generateLayout('tile') },
  });

  //비디오 순서 변경  , 스피커 설정
  useEffect(() => {
    for (let i = 0; participantStore.participants.length > i; i++) {
      if (i === videoFirstIndex) {
        const positionFixed = document.getElementById('girdBoxId' + videoFirstIndex) as HTMLElement;
        if (lastView === 'tile2' && positionFixed) {
          positionFixed.style.position = 'fixed';
        }
      } else {
        const positionAbsolute = document.getElementById('girdBoxId' + i) as HTMLElement;
        if (lastView === 'tile2' && positionAbsolute) {
          positionAbsolute.style.position = 'absolute';
        }
      }
    }

    const video: any = document.querySelectorAll('video');
    for (let i = 0; video.length > i; i++) {
      if (i > 0) {
        if (typeof video[i].sinkId !== 'undefined' && audioOutput !== null) {
          video[i]
            .setSinkId(audioOutput)
            .then(() => {
              console.log(`Success, audio output device attached: ${audioOutput}`);
            })
            .catch((error: any) => {
              let errorMessage = error;
              if (error.name === 'SecurityError') {
                errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
              }
              console.error(errorMessage);
              setAudioInput('');
            });
        } else {
          console.warn('Browser does not support output device selection.');
        }
      }
    }
  }, [videoFirstIndex, participantStore.participants.length]);

  useEffect(() => {
    setMobileHight(fullScreen - 135);
    setMobileWidth(fullWidth);
  }, [fullScreen]);

  //비디오 배열 설정(타일)
  function generateLayout(type: any, index?: number) {
    let sq: number;
    sq = participantStore.participants.length;
    let root = document.getElementById('root');
    if (root) {
      totalHeight = root?.clientHeight - HEADER.H_MAIN_DESKTOP * 2 - 32;
    }
    gridHight = (totalHeight - 40) / 3;
    if (root) {
      fullScreen = root?.clientHeight;
      fullWidth = root?.clientWidth;
      if (openSide) {
        gridWidth = root?.clientWidth - 40 - drawerWidth;
      } else {
        gridWidth = root?.clientWidth - 40;
      }
    }
    if (index === undefined) {
      if (videoFirstIndex !== 0) {
        index = videoFirstIndex;
      } else {
        index = 0;
      }
    }
    return _.map(_.range(0, sq), function (item, i) {
      if (type) {
        if (type === 'full') {
          if (i === index) {
            return {
              x: 0,
              y: 0,
              w: 3,
              h: 1,
              maxH: gridHight * 3,
              maxW: gridWidth,
              i: i.toString(),
              static: true,
            };
          } else {
            return {
              x: 0,
              y: 0,
              w: 0,
              h: 0,
              maxH: 0,
              maxW: 0,
              i: i.toString(),
            };
          }
        }
        if (type === 'tile2') {
          if (i === index) {
            return {
              x: 0,
              y: 0,
              w: 2,
              h: 3,
              maxH: gridHight * 3,
              maxW: (gridWidth * 2) / 3,
              i: i.toString(),
              static: true,
            };
          } else {
            return {
              x: 2,
              y: 0,
              w: 1,
              h: 1,
              maxH: gridHight,
              maxW: gridWidth / 3,
              i: i.toString(),
              static: true,
            };
          }
        }
        if (type === 'tile') {
          return {
            x: i % 3,
            y: 0,
            w: 1,
            h: 1.5,
            maxH: (gridHight * 3) / 2,
            maxW: gridWidth / 3,
            i: i.toString(),
          };
        }
        if (type === 'OneAndOne') {
          if (i === 0) {
            return {
              x: 0,
              y: 0,
              w: 1.5,
              h: 3,
              maxH: gridHight * 3,
              maxW: (gridWidth * 2) / 3,
              i: i.toString(),
              static: true,
            };
          } else {
            return {
              x: 2,
              y: 0,
              w: 1.5,
              h: 3,
              maxH: gridHight * 3,
              maxW: (gridWidth * 2) / 3,
              i: i.toString(),
              static: true,
            };
          }
        }
      }
      return {
        x: i,
        y: 0,
        w: 1,
        h: 1,
        i: i.toString(),
      };
    });
  }

  const onClickEvent = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    e.target.parentElement.children[0].requestFullscreen();
  };

  const onLayout = (cuid: string) => {
    if (!pinMode) {
      setDataPinMode(true);
      pinMode = true;
      const index = participantStore.participants.map((p: IParticipant, i) => {
        if (p.cuid === cuid) {
          return i;
        }
      });
      const videoIndex = index.find((item: any) => {
        return item !== undefined;
      });

      setLayoutType(layoutType + Math.random());
      videoFirstIndex = videoIndex;
      videoFirstCUid = cuid;
      layoutState.layouts.lg = generateLayout(lastView, videoIndex);
    } else {
      setDataPinMode(false);
      pinMode = false;
    }
  };

  const onDragStop = (e: any, oldItem: any, layout: any) => {
    if (viewMode === 'tile2') {
      for (let i = 1; e.length > i; i++) {
        if (e[i].x < 3) {
          e[i].x = 3;
          return;
        }
      }
    }
  };
  const [data, setData] = useState<IConferenceSnapshot>({} as IConferenceSnapshot);

  const getDetail = async (id: number) => {
    await CallApiToStore(conferenceStore.get(id), 'api', loadingStore).then(() => {
      state.room.hasChat = toJS(conferenceStore.conference.room.hasChat);
      state.room.hasReport = toJS(conferenceStore.conference.room.hasReport);
      setData(toJS(conferenceStore.conference));
    });
  };

  const isPad = (() => {
    const { userAgent, maxTouchPoints } = window.navigator;
    const isPad = /macintosh/i.test(userAgent.toLowerCase());
    const isTab = /samsungbrowser/i.test(userAgent.toLowerCase()) && !/mobi/i.test(userAgent);
    if ((isPad || isTab) && maxTouchPoints > 0) {
      return true;
    } else if (/tablet|ipad|playbook|silk/i.test(userAgent.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  })();

  const navColors = {
    active: '#FFFFFF',
    inactive: '#919EAB',
  };
  const [navState, setNavState] = useState<any>({});
  const navConfig = [
    {
      title: translate(`meeting.participant`).toString(),
      value: 'participant',
      icon: IcoPeople,
      callback: onViewParticipant,
      checkKey: 'participant',
      owner: false,
      useDownMd: true,
    },

    {
      title: translate(`meeting.record`).toString(),
      value: 'record',
      icon: IcoRecord,
      callback: onRecord,
      owner: true,
      activeColor: '#FF0000',
      useDownMd: false,
      recordYn: state.room.allowRecording,
    },
    {
      title: translate(`meeting.chat`).toString(),
      value: 'chat',
      icon: IcoChat,
      callback: onViewChat,
      checkKey: 'chat',
      owner: false,
      useDownMd: true,
    },
    {
      title: translate(`meeting.whiteboard`).toString(),
      value: 'board',
      icon: IcoWhiteBoard,
      callback: onWhiteBoard,
      owner: false,
      useDownMd: !isPad ? false : true,
      // useDownMd: navigator.userAgent.match(/iPad/i) == null ? false : true, // ipad에서만 => whiteboard표시
    },
    {
      title: translate(`meeting.screenShare`).toString(),
      value: 'shareScreen',
      icon: IcoShareScreen,
      callback: onShareScreen,
      owner: false,
      useDownMd: false,
    },
    {
      title: translate(`meeting.fileShare`).toString(),
      value: 'shareDoc',
      icon: IcoShareDoc,
      callback: onShareFiles,
      checkKey: 'share',
      owner: false,
      useDownMd: true,
    },
    {
      title: translate(`meeting.report.mainTitle`).toString(),
      value: 'writeReport',
      icon: IcoWriteReport,
      callback: onReport,
      owner: true,
      useDownMd: false,
    },
  ];

  useEffect(() => {
    getDetail(state.id);
    sessionStorage.removeItem('CCInfo');
  }, []);

  const [reportData, setReportData] = useState({} as IReportSnapshot);
  const [openReport, setOpenReport] = useState(false);
  const getReport = async (roomId: number) => {
    await CallApiToStore(conferenceStore.getReport(roomId), 'api', loadingStore)
      .then(() => {
        if (responseStore.responseInfo.error && responseStore.responseInfo.exceptionDetail) {
          setOpenReport(true);
        } else {
          setReportData(toJS(conferenceStore.report) as IReportSnapshot);
          setOpenReport(true);
        }
      })
      .catch((e) => {
        console.log('🪄 ~ awaitCallApiToStore ~ e:', e);
      });
  };

  const camFlag = (flag: any) => {
    setNoCam(flag);
  };

  const handleMouseDown = (e: any) => {
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMove, true);
  };

  const onOpenScreen = (flag: any) => {
    setOpenScreen(flag);
  };

  const handleMouseMove = useCallback((e: any) => {
    const newWidth = document.body.clientWidth - (document.body.offsetLeft + e.clientX);
    if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
      setDrawerWidth(newWidth);
    }
  }, []);

  const sidebarProps = {
    sideState: sideState,
    setSideState: setSideState,
    setOpenSide: setOpenSide,
    data: data,
    chatMsg: chatMsg,
    newChatMsg: newChatMsg,
    getChat: getChat,
  };

  const detailProps = {
    conferenceDetail: conferenceDetail,
    openConferenceDetail: openConferenceDetail,
    handleConferenceClose: handleConferenceClose,
    data: data,
    getDetail: getDetail,
  };

  const [whiteboardMessage, setWhiteboardMessage] = useState<any>(null);
  const whiteboardProps = {
    roomId: conferenceStore.conference.room.id,
    sendMessage: sendMessage,
    message: whiteboardMessage,
    onWhiteBoard: onWhiteBoard,
    setNavState: setNavState,
  };

  useEffect(() => {
    setWhiteboardMessage(null);
    return () => {
      setWhiteboardMessage(null);
    };
  }, []);

  //방닫기
  const onRoomClose = async () => {
    if (state.ownerYn === 'Y' && isAlertOpen === false) {
      setIsAlertOpen(true);
      setAlertMessage(translate(`meeting.alert.endAlert`).toString());
    } else {
      const myCUID = sessionStorage.getItem('myCUID') || '';
      sessionStorage.removeItem('reInitData');
      const data = {
        cuid: myCUID,
      };
      await CallApiToStore(meetingStore.closeRoom(data), 'api', loadingStore)
        .then((data) => {
          if (stream) {
            stream.getTracks().forEach((track: any) => {
              track.stop();
            });
          }
          const video = document.querySelector('video');
          if (video) {
            video.srcObject = null;
          }
          ws.close();
          window.location.href = '/';
        })
        .catch((res) => {
          console.log('res', res);
        });
    }
  };

  const footerProps = {
    state: state,
    leaveRoom: leaveRoom,
    onToggleMic: onToggleMic,
    toggleMic: toggleMic,
    onToggleVideo: onToggleVideo,
    toggleVideo: toggleVideo,
    openSet: openSet,
    handleModalSettingOpen: handleModalSettingOpen,
    navConfig: navConfig,
    navColors: navColors,
    handleScreenOpen: handleScreenOpen,
    share: share,
    sideState: sideState,
    navState: navState,
    data: data,
    setNavState: setNavState,
    openSide: openSide,
    setOpenSide: setOpenSide,
    sidebarProps: sidebarProps,
    newChatMsg: newChatMsg,
    buttonMicFlag: buttonMicFlag,
    buttonVideoFlag: buttonVideoFlag,
    mobile: mobile,
    ownerYn: state.ownerYn,
    onRoomClose: onRoomClose,
  };

  const meetingProps = {
    state: state,
    leaveRoom: leaveRoom,
    footerProps: footerProps,
    mobileHight: mobileHight,
    mobileWidth: mobileWidth,
    videoFirstIndex: mobileIndex,
    mobileChange: mobileChange,
    sendMessage: sendMessage,
    onConnectionstatechange: onConnectionstatechange,
    addPeer: addPeer,
    viewMode: viewMode,
    handleToggleMic: handleToggleMic,
    handStream: handStream,
    handleToggleVideo: handleToggleVideo,
    getDetail: getDetail,
    MobileFullWidth: fullWidth,
    MobileFullScreen: fullScreen - 135,
    data: data,
    stream: stream,
    onButtonFlag: onButtonFlag,
    returnAlertFlag: onAlertFlag,
    mobile: mobile,
    ownerYn: state.ownerYn,
    onRoomClose: onRoomClose,
    handVideoStream: handVideoStream,
    isUnderPerformance: isUnderPerformance,
  };

  const onReReadyFlag = () => {
    setReReady(false);
  };

  const sendLog = async (content: any) => {
    await CallApiToStore(meetingStore.saveLog(content), 'api', loadingStore).then(() => {
      setTimeout(() => {
        checkSpeed();
      }, 30000); // 1분 -> 30초마다 speed check 및 saveLog
    });
  };
  const [mediaDevices, setMediaDevices] = useState<null | Array<any>>(null);
  useEffect(() => {
    if (mediaDevices) {
      const filteredDevices = mediaDevices
        .filter(
          (v: any) =>
            v.deviceId === videoInput || v.deviceId === audioInput || v.deviceId === audioOutput,
        )
        .reduce((acc, device) => {
          if (!acc[device.kind]) {
            acc[device.kind] = {};
          }
          acc[device.kind] = device;
          return acc;
        }, {});
      mediaDevicesInfo = filteredDevices;
    }
  }, [audioInput, audioOutput, mediaDevices, videoInput]);

  let networkInfo = {};
  const checkSpeed = async () => {
    await meetingStore.checkDownloadSpeed(
      // `${REACT_APP_API_URL}/web/networktest?size=5242880`,
      `${REACT_APP_API_URL}/web/networktest?size=1258291`, // 0.5MB -> 1.2MB
      async (downloadSpeed: number, data: any) => {
        await meetingStore.checkUploadSpeed(data, (uploadSpeed: number) => {
          const logContents = {
            userAgent: window.navigator.userAgent, // 기기 정보, 브라우저 정보
            userInfo: JSON.parse(localStorage.getItem('user') || ''), // 사용자 정보
            mediaDevicesInfo: mediaDevicesInfo, // 미디어디바이스 정보
          };
          setIsUnderPerformance(uploadSpeed < 1.2);
          // setVideoSize(uploadSpeed < 1.2 ? '360p' : '720p');
          networkInfo = {
            downloadSpeed: downloadSpeed + 'Mbps',
            uploadSpeed: uploadSpeed + 'Mbps',
          };
          sendLog({ ...logContents, networkInfo });
          // console.log('🚀🚀🚀 ~  uploadSpeed:', uploadSpeed);
          // console.log('🚀🚀🚀 ~  networkInfo, videoSize:', networkInfo, videoSize);
        });
      },
    );
  };

  useEffect(() => {
    checkSpeed();
  }, []);

  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('user');
  const handleChangeFacingMode = () => {
    facingMode === 'user' ? setFacingMode('environment') : setFacingMode('user');
  };

  return (
    <>
      {ready === false && (
        <Waiting
          initialize={initialize}
          requestMessage={requestMessage}
          reReady={reReady}
          ready={ready}
          ws={ws}
          flag={onReReadyFlag}
          connectionSuccess={connectionSuccess}
          data={data}
          mobile={mobile}
        />
      )}
      {ready === true &&
        (isDownMd ? (
          <Box id="meetingWrapper">
            {isWhiteboard && <WhiteBoard {...whiteboardProps} />}
            <MeetingM {...meetingProps} />
          </Box>
        ) : (
          <Box style={{ display: 'flex', flex: 1, height: '100%' }}>
            <MeetingWrapper id="meetingWrapper" open={openSide} drawerwidth={drawerWidth}>
              <AppBar
                color="transparent"
                sx={{
                  boxShadow: 0,
                  background: theme.palette.background.paper,
                  left: 0,
                  width: '100%',
                  minWidth: 'md',
                  transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                  }),
                  ...(openSide && {
                    width: `calc(100% - ${drawerWidth}px)`,
                    transition: theme.transitions.create('width', {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.enteringScreen,
                    }),
                  }),
                }}
              >
                <Toolbar
                  disableGutters
                  sx={{
                    height: {
                      xs: HEADER.H_MOBILE,
                      md: HEADER.H_MAIN_DESKTOP,
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      height: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      p: 2,
                    }}
                  >
                    <Stack direction={'row'} spacing={1}>
                      {mobile && (
                        <IconButton id={'camReversal'} onClick={handleChangeFacingMode}>
                          <Iconify icon={'ic:sharp-flip-camera-ios'} color={'#999999'} />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={viewSummary}
                        sx={{ background: theme.palette.background.neutral, borderRadius: '50%' }}
                      >
                        <IcoRoomInfo fill={'#919EAB'} />
                      </IconButton>
                    </Stack>
                    <Stack
                      direction={'row'}
                      spacing={1}
                      sx={{ alignItems: 'baseline', textAlign: 'left', px: 2 }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          textAlign: 'center',
                          width: '100%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {data.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        color={theme.palette.text.secondary}
                        sx={{ whiteSpace: 'nowrap' }}
                      >
                        {translate(`type.${conferenceStore.conference.room.type}`).toString()}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={'row'}
                      sx={{
                        border: '1px solid ' + theme.palette.divider,
                        borderRadius: 1,
                        background: theme.palette.background.neutral,
                      }}
                    >
                      {/* <IconButton
                        onClick={() => {
                          toggleViewMode('OneAndOne');
                        }}
                      >
                        <IcoViewmodeTile fill={viewMode === 'OneAndOne' ? '#00AB55' : '#919EAB'} />
                      </IconButton> */}
                      <IconButton
                        onClick={() => {
                          toggleViewMode('tile');
                          setDataPinMode(false);
                          pinMode = false;
                        }}
                      >
                        <IcoViewmodeTile fill={viewMode === 'tile' ? '#00AB55' : '#919EAB'} />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          toggleViewMode('tile2');
                        }}
                      >
                        <IcoViewmodePin fill={viewMode === 'tile2' ? '#00AB55' : '#919EAB'} />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          toggleViewMode('full');
                          setDataPinMode(false);
                          pinMode = false;
                        }}
                      >
                        <IcoViewmodeFull fill={viewMode === 'full' ? '#00AB55' : '#919EAB'} />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          onViewParticipant();
                        }}
                      >
                        <IcoViewmodeMan
                          fill={openSide && sideContent === 'participant' ? '#00AB55' : '#919EAB'}
                        />
                      </IconButton>
                    </Stack>
                  </Box>
                </Toolbar>
              </AppBar>
              {isWhiteboard && <WhiteBoard {...whiteboardProps} />}
              <MeetingMain sx={{ p: 2 }} open={openSide} drawerwidth={drawerWidth}>
                <Scrollbar
                  // scrollableNodeProps={{
                  //   ref: scrollRef,
                  // }}
                  sx={{ height: 1 }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      height: totalHeight,
                      overflowY: 'auto',
                      ...(openSide && {
                        width: `calc(100% - ${drawerWidth}px)`,
                        transition: theme.transitions.create('width', {
                          easing: theme.transitions.easing.sharp,
                          duration: theme.transitions.duration.enteringScreen,
                        }),
                      }),
                    }}
                    className={'Boxhover'}
                  >
                    <GridLayout
                      cols={3}
                      rowHeight={gridHight}
                      width={gridWidth}
                      layout={layoutState.layouts.lg}
                      onDragStop={(newLayout, oldItem, layout) =>
                        onDragStop(newLayout, oldItem, layout)
                      }
                    >
                      {_.map(layoutState.layouts.lg, function (l, seq) {
                        return (
                          <Box
                            key={seq}
                            display={'grid'}
                            id={'girdBoxId' + seq}
                            onDoubleClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                          >
                            {participantStore.participants.map(
                              (participant: IParticipant, i: number) => {
                                if (participant.stream?.activities[0] !== 'SCREEN') {
                                  if (seq === i) {
                                    let videoView: any = 'video';
                                    const streamVideo = participant.stream?.activities.find(
                                      (item) => {
                                        return item === 'VIDEO';
                                      },
                                    );
                                    const streamAudio = participant.stream?.activities.find(
                                      (item) => {
                                        return item === 'AUDIO';
                                      },
                                    );

                                    if (streamVideo !== undefined) {
                                      videoView = 'video';
                                    } else if (streamAudio !== undefined) {
                                      videoView = 'audio';
                                    } else {
                                      videoView = 'video';
                                    }

                                    return (
                                      <Grid
                                        item
                                        key={'participant-' + i}
                                        style={{ display: 'grid', height: l.maxH }}
                                      >
                                        <Participant
                                          key={'participant-' + i}
                                          participant={participant}
                                          sendMessage={sendMessage}
                                          onConnectionstatechange={onConnectionstatechange}
                                          setPeer={addPeer}
                                          videostate={state}
                                          videoHeight={l.maxH}
                                          ToggleMic={handleToggleMic}
                                          Mestream={handStream}
                                          myStream={stream}
                                          ToggleVideo={handleToggleVideo}
                                          videoWidth={l.maxW}
                                          videoView={videoView}
                                          screenMode={false}
                                          detaildata={data}
                                          videoStream={handVideoStream}
                                          setNoCam={camFlag}
                                          onButtonFlag={onButtonFlag}
                                          browserName={browserName}
                                          returnAlertFlag={onAlertFlag}
                                          layout={onLayout}
                                          type={lastView}
                                          pinMode={dataPinMode}
                                          videoFirstCUid={videoFirstCUid}
                                          mobile={mobile}
                                          facingMode={facingMode}
                                          isUnderPerformance={isUnderPerformance}
                                        />
                                        {i === 0 &&
                                          participantStore.screenParticipant.map(
                                            (screenParticipant: IParticipant, i: number) => {
                                              const myCUID = sessionStorage.getItem('myCUID');
                                              if (myCUID === screenParticipant.stream?.cuid) {
                                                return (
                                                  <Box
                                                    id={'screenId' + i}
                                                    key={'participant-' + i}
                                                    sx={{
                                                      position: 'absolute',
                                                      top: 10,
                                                      right: 10,
                                                    }}
                                                    onDoubleClick={onClickEvent}
                                                  >
                                                    <ScreenParticipant
                                                      participant={screenParticipant}
                                                      sendMessage={sendMessage}
                                                      onConnectionstatechange={
                                                        onConnectionstatechange
                                                      }
                                                      setPeer={addScreenPeer}
                                                      screenStream={screen}
                                                      videoWidth={l.maxW / 4}
                                                      videoHeight={l.maxH / 4}
                                                    />
                                                  </Box>
                                                );
                                              }
                                            },
                                          )}
                                      </Grid>
                                    );
                                  }
                                }
                              },
                            )}
                          </Box>
                        );
                      })}
                    </GridLayout>
                    <Box>
                      {participantStore.recordParticipant.map(
                        (recordParticipant: IParticipant, i: number) => {
                          const myCUID = sessionStorage.getItem('myCUID');
                          if (myCUID === recordParticipant.stream?.cuid) {
                            return (
                              <RecordParticipant
                                key={'participant-' + i}
                                participant={recordParticipant}
                                sendMessage={sendMessage}
                                onConnectionstatechange={onConnectionstatechange}
                                setPeer={addRecordPeer}
                                recordStream={record}
                              />
                            );
                          }
                        },
                      )}
                    </Box>
                  </Box>
                </Scrollbar>
              </MeetingMain>
              <MeetingFooter open={openSide} drawerwidth={drawerWidth} elevation={3}>
                <Footer {...footerProps} />
              </MeetingFooter>
            </MeetingWrapper>
            {openSide && (
              <Drawer
                variant="permanent"
                anchor="right"
                open={openSide}
                PaperProps={{
                  style: {
                    width: drawerWidth,
                    overflowX: 'hidden',
                    flexShrink: 0,
                  },
                }}
              >
                <Stack
                  spacing={'2px'}
                  justifyContent={'center'}
                  onMouseDown={(e) => handleMouseDown(e)}
                  sx={{
                    width: '4px',
                    cursor: 'ew-resize',
                    padding: '3px 0 0',
                    borderTop: '1px solid #ddd',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    zIndex: 100,
                    backgroundColor: 'rgb(30, 34, 40)',
                  }}
                >
                  <Divider sx={{ borderWidth: '2px', borderColor: theme.palette.divider }} />
                  <Divider sx={{ borderWidth: '2px', borderColor: theme.palette.divider }} />
                  <Divider sx={{ borderWidth: '2px', borderColor: theme.palette.divider }} />
                </Stack>
                <Sidebar {...sidebarProps} />
              </Drawer>
            )}
          </Box>
        ))}
      {openAdmission && (
        <Dialog
          open={openAdmission}
          maxWidth={'sm'}
          fullWidth
          PaperProps={{
            sx: {
              borderRadius: 4,
            },
          }}
        >
          <Admission data={requestRoomAccept} clear={onAdmissionClear} />
        </Dialog>
      )}
      {openReport && (
        <Dialog
          open={openReport}
          maxWidth={'md'}
          fullWidth
          scroll="body"
          PaperProps={{
            sx: {
              borderRadius: 4,
            },
          }}
          onClose={(e, reason) => {
            reason !== 'backdropClick' && setOpenReport(false);
            reason !== 'backdropClick' &&
              footerProps.setNavState(
                (navState: any) =>
                  (navState = {
                    ...navState,
                    controlButton_6: navState['controlButton_6']
                      ? !navState['controlButton_6']
                      : true,
                  }),
              );
          }}
        >
          <ReportCreate
            onClose={() => {
              setOpenReport(false);
              footerProps.setNavState(
                (navState: any) =>
                  (navState = {
                    ...navState,
                    controlButton_6: navState['controlButton_6']
                      ? !navState['controlButton_6']
                      : true,
                  }),
              );
            }}
            data={data}
            path={data.room.hasReport === true ? 'edit' : undefined}
            reportData={reportData}
            getData={() => getDetail(data.id)}
          />
        </Dialog>
      )}
      {openConferenceDetail && ( // 회의상세정보 Dialog
        <DetailPopOver {...detailProps} />
      )}
      {/* 화면공유 */}
      {openScreen === true && (
        <Dialog
          open={openScreen}
          fullWidth={true}
          maxWidth={false}
          PaperProps={{
            sx: {
              maxWidth: { fullWidth },
              borderRadius: 4,
              width: fullWidth,
              height: fullScreen - 60,
            },
          }}
          onClose={(e, reason) => {
            setOpenScreen(false);
          }}
        >
          {participantStore.screenParticipant.map((participant: IParticipant, i: number) => {
            if (participant.stream?.activities[0] === 'SCREEN') {
              return (
                <Participant
                  videoWidth={fullWidth}
                  videoHeight={fullScreen - 60}
                  key={'participant-' + i}
                  participant={participant}
                  sendMessage={sendMessage}
                  onConnectionstatechange={onConnectionstatechange}
                  setPeer={addPeer}
                  videostate={state}
                  ToggleMic={handleToggleMic}
                  Mestream={handStream}
                  ToggleVideo={handleToggleVideo}
                  videoView={'video'}
                  screenMode={true}
                  setOpenScreen={onOpenScreen}
                  mobile={mobile}
                />
              );
            }
          })}
        </Dialog>
      )}
      {openModalSetting && (
        <ModalSetting
          handleModalSettingClose={handleModalSettingClose}
          returnVideoValue={returnVideoValue}
          fullScreen={fullScreen / 5}
          fullWidth={fullWidth / 4}
          anchorSet={anchorSet}
          videoMic={state.videoMic}
          videoVol={state.videoVol}
          view={'Meeting'}
          toggleMic={toggleMic}
          onToggleMic={onToggleMic}
          handleToggleMic={handleToggleMic}
          mobile={mobile}
          deviceChange={deviceChange}
        ></ModalSetting>
      )}

      {errorAlertFlag && (
        <Dialog
          open={errorAlertFlag}
          maxWidth={'sm'}
          PaperProps={{
            sx: {
              borderRadius: 4,
              p: 4,
            },
          }}
          onClose={(e, reason) => {
            reason !== 'backdropClick' && setAlertFlag(false);
          }}
        >
          <Stack spacing={2} sx={{ minWidth: 250 }}>
            <Typography textAlign={'center'} variant="h6">
              {errorAlertMessage}
            </Typography>
            <Stack justifyContent={'center'} direction={'row'} gap={1}>
              <Button
                variant={'contained'}
                color={'error'}
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                {translate(`meeting.y`).toString()}
              </Button>
            </Stack>
          </Stack>
        </Dialog>
      )}

      {alertFlag && (
        <Dialog
          open={alertFlag}
          maxWidth={'sm'}
          PaperProps={{
            sx: {
              borderRadius: 4,
              p: 4,
            },
          }}
          onClose={(e, reason) => {
            reason !== 'backdropClick' && setAlertFlag(false);
          }}
        >
          <Stack spacing={2} sx={{ minWidth: 250 }}>
            <Typography textAlign={'center'} variant="h6">
              {alertMessage}
            </Typography>
            <Stack justifyContent={'center'} direction={'row'} gap={1}>
              <Button
                variant={'contained'}
                color={'error'}
                onClick={() => {
                  setAlertFlag(false);
                  if (roomClose === true) {
                    onRoomClose();
                  }
                }}
              >
                {translate(`meeting.y`).toString()}
              </Button>
            </Stack>
          </Stack>
        </Dialog>
      )}
      {isAlertOpen && (
        <Dialog
          open={isAlertOpen}
          maxWidth={'sm'}
          PaperProps={{
            sx: {
              borderRadius: 4,
              p: 4,
            },
          }}
          onClose={(e, reason) => {
            reason !== 'backdropClick' && setIsAlertOpen(false);
          }}
        >
          <Stack spacing={2} sx={{ minWidth: 250 }}>
            <Typography textAlign={'center'} variant="h6">
              {alertMessage}
            </Typography>
            <Stack justifyContent={'center'} direction={'row'} gap={1}>
              <Button
                variant="soft"
                onClick={() => {
                  setIsAlertOpen(false);
                }}
              >
                {translate(`meeting.n`).toString()}
              </Button>
              <Button variant={'contained'} color={'error'} onClick={onRoomClose}>
                {translate(`meeting.y`).toString()}
              </Button>
            </Stack>
          </Stack>
        </Dialog>
      )}
      {webSocketAlertOpen && (
        <Dialog
          open={webSocketAlertOpen}
          maxWidth={'sm'}
          PaperProps={{
            sx: {
              borderRadius: 4,
              p: 4,
            },
          }}
          onClose={(e, reason) => {
            reason !== 'backdropClick' && setWebSocketAlertOpen(false);
          }}
        >
          <Stack spacing={2} sx={{ minWidth: 250 }}>
            <Typography textAlign={'center'} variant="h6">
              {webSocketAlertMessage}
            </Typography>
            <Stack justifyContent={'center'} direction={'row'} gap={1}>
              <Button
                variant="soft"
                onClick={() => {
                  setWebSocketAlertOpen(false);
                }}
              >
                {translate(`meeting.ok`).toString()}
              </Button>
            </Stack>
          </Stack>
        </Dialog>
      )}

      {isRecordAlertOpen && mobile === false && (
        <Dialog
          open={isRecordAlertOpen}
          maxWidth={'sm'}
          PaperProps={{
            sx: {
              borderRadius: 4,
              p: 4,
            },
          }}
          onClose={(e, reason) => {
            reason !== 'backdropClick' && setIsRecordAlertOpen(false);
          }}
        >
          <Stack spacing={2} sx={{ minWidth: 250 }}>
            <Typography textAlign={'center'} variant="h6">
              {translate(`meeting.autoRecord`).toString()}
            </Typography>
            <Stack justifyContent={'center'} direction={'row'} gap={1}>
              <Button
                variant="soft"
                onClick={() => {
                  setIsRecordAlertOpen(false);
                }}
              >
                {translate(`meeting.n`).toString()}
              </Button>
              <Button
                variant={'contained'}
                color={'error'}
                onClick={() => {
                  onRecord();
                  onNavState(1);
                  setIsRecordAlertOpen(false);
                }}
              >
                {translate(`meeting.y`).toString()}
              </Button>
            </Stack>
          </Stack>
        </Dialog>
      )}
    </>
  );
});

const MeetingWrapper = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
  drawerwidth: number;
}>(({ theme, open, drawerwidth }) => ({
  flexGrow: 1,
  paddingTop: HEADER.H_MAIN_DESKTOP,
  marginRight: open ? -drawerwidth : 0,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}));

const MeetingFooter = styled(Paper)<{
  open?: boolean;
  drawerwidth: number;
}>(({ theme, open, drawerwidth }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: theme.zIndex.appBar,
  borderRadius: 0,
  background: '#000000',
  alignSelf: 'center',
  width: '100%',
  m: 'auto',
  flexGrow: 1,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: open ? `calc(100% - ${drawerwidth}px)` : '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MeetingMain = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
  drawerwidth: number;
}>(({ theme, open, drawerwidth }) => ({
  flexGrow: 1,
  marginRight: open ? -drawerwidth : 0,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}));

export default Meeting;
